import { Button, Typography } from "@mui/material";
import Symbol from "../Icons/Symbol";
import Modal from "../Modal";
import { Buttons, Content, Title } from "./styled";

const ConfirmModal = ({ options, close }) => {
  const { title, text, icon, button, width } = options;

  return (
    <Modal width={width ?? 400} hideCloseButton>
      <Content>
        {Boolean(icon) && (
          <Symbol
            name={icon?.name || icon}
            variant="contained"
            color={icon?.color || "grey500"}
            fontSize="large"
          />
        )}

        <Title>{title}</Title>
        <Typography variant="body1" color="text.grey700">
          {text}
        </Typography>

        <Buttons direction={button?.direction || "horizontal"}>
          <Button
            color={button?.color || button?.confirm?.color || "danger"}
            onClick={() => close("confirm")}
            variant="contained"
            size="large"
          >
            {button?.text || button?.confirm?.text || "확인했어요"}
          </Button>
          <Button variant="outlined" color="secondary" size="large" onClick={close}>
            {button?.cancel?.text || button?.cancel || "취소"}
          </Button>
        </Buttons>
      </Content>
    </Modal>
  );
};

export default ConfirmModal;
