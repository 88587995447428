import { put, call, takeLatest } from "redux-saga/effects";
import { serialize } from "object-to-formdata";
import { apiRequest } from "server_url";
import { action as prepaymentAction } from "modules/prepayment";
import { action as loadingAction } from "modules/loading";
import { dataURLtoFile } from "helpers/base64_to_file";
import { priceFormat } from "helpers/format";
import { dateToString } from "helpers/moment";

function* getEventCount() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/count/",
      method: "GET",
    });

    yield put(prepaymentAction.setEventCount(response.data));
  } catch (error) {}
}

function* getEvents({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/cpc/list/",
      method: "GET",
      params: {
        start_date: payload?.startDate || dateToString(),
        end_date: payload?.endDate || dateToString(),
        sort_key: payload?.sort_key || "default",
        // sleep: 60 * 1000,
      },
      errorMsg:
        "불러오는 내역이 많아 정보를 불러오는데 실패했습니다.\n조회기간을 줄여 다시 시도해주세요.",
    });

    yield put(prepaymentAction.setEvents(response.data));
  } catch (error) {}
  yield put(loadingAction.successDescription());
}

function* getEvent({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/",
      method: "GET",
      params: {
        prepayment_event_id: payload,
      },
    });

    yield put(prepaymentAction.setEvent({ ...response.data, id: payload }));
  } catch (error) {}
}

function* getOptions({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/",
      method: "GET",
      params: {
        prepayment_event_id: payload,
      },
    });

    yield put(prepaymentAction.setOptions({ ...response.data, id: payload }));
  } catch (error) {}
}

function* postEvent({ payload }) {
  const { data, callback } = payload;
  const { customBannerImage, customEventImage } = data;

  try {
    yield call(apiRequest, {
      url: "/prepayment_event/",
      method: data.id ? "PUT" : "POST",
      data: serialize({
        prepayment_event_id: data.id,
        name: data.name,
        description: data.description,
        target: data.target,
        side_effects: data.side_effects,
        start_date: data.start_date,
        end_date: data.end_date,
        img: data.img?.url ? dataURLtoFile(data.img?.url, "img") : data.img,
        timg: data.timg?.url ? dataURLtoFile(data.timg?.url, "timg") : data.timg,
        sub_category_filter1: data.sub_category_filter1,
        sub_category_filter2: 0,
        sub_category_filter3: 0,
        features: JSON.stringify([
          ...Object.values(data.features)
            .filter(value => value.checked)
            .map(item => {
              if (item.feature === "anesthesia") {
                return {
                  ...item,
                  text1: parseInt(item.text1) > 0 ? priceFormat(item.text1) : 0,
                };
              }
              return item;
            }),
          ...data.features.feature,
        ]),
        options: JSON.stringify(
          data.options.map(item => ({
            ...item,
            option_id:
              typeof item.option_id === "string" && item.option_id?.includes("_")
                ? null
                : item.option_id,
          }))
        ),
        ...(data?.hasCustomBannerImage
          ? {
              custom_timg: JSON.stringify({
                style: customBannerImage.layoutType,
                background: customBannerImage.color,
                title: customBannerImage.title,
                description: customBannerImage.checkedDescription
                  ? customBannerImage.description
                  : null,
                price: customBannerImage.checkedPrice ? customBannerImage.price : null,
              }),
            }
          : {}),
        ...(data?.hasCustomEventImage
          ? {
              custom_img: JSON.stringify({
                ...customEventImage.list.reduce(
                  (result, item, index) => ({
                    ...result,
                    [`title${index + 1}`]: item.title,
                    [`description${index + 1}`]: item.description || null,
                  }),
                  {}
                ),
                additional_description: customEventImage.description,
              }),
            }
          : {}),
      }),
    });

    callback && callback();
  } catch (error) {
    error?.response?.data?.message && alert(error?.response?.data?.message);
  }
}

function* stopEvent({ payload }) {
  try {
    const { id, scmt } = payload;
    yield call(apiRequest, {
      url: "/prepayment_event/stop/",
      method: "PUT",
      data: {
        prepayment_event_id: id,
        scmt,
      },
    });

    yield put(prepaymentAction.stopEventSuccess({ id, scmt }));
  } catch (error) {}
}

function* deleteEvent({ payload }) {
  try {
    yield call(apiRequest, {
      url: "/prepayment_event/",
      method: "DELETE",
      data: {
        prepayment_event_id: payload,
      },
    });

    alert("삭제 되었습니다.");
    yield put(prepaymentAction.deleteEventSuccess(payload));
  } catch (error) {}
}

function* getSubCategories() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/sub_categories/",
      method: "GET",
    });

    yield put(prepaymentAction.setSubCategories(response.data));
  } catch (error) {}
}

function* getFilters({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/sub_categories/filters/",
      method: "GET",
      params: { sub_category_id: payload },
    });

    yield put(prepaymentAction.setFilters({ id: payload, list: response.data }));
  } catch (error) {}
}

function* getFilterAll() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/sub_categories/filters/all/",
      method: "GET",
    });

    yield put(prepaymentAction.setFilterAll(response.data));
  } catch (error) {}
}

function* getTreatmentList({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/treatment/list/",
      method: "GET",
    });
    yield put(prepaymentAction.setTreatmentList(response.data));
    payload.callback && payload.callback();
  } catch (error) {}
}

function* getTreatments() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/treatment/tree/",
      method: "GET",
    });

    yield put(prepaymentAction.setTreatments(response.data));
  } catch (error) {}
}

function* postTreatment({ payload }) {
  const { sub_category_filter, sub_category_filter_name, detail } = payload;
  try {
    yield call(apiRequest, {
      url: "/prepayment_event/treatment/",
      method: "POST",
      data: {
        sub_category_filter,
        sub_category_filter_name,
        detail_list: detail.length ? detail : [{ info: null, price: payload.price }],
      },
    });
  } catch (error) {}
}

function* deleteTreatment({ payload }) {
  try {
    yield call(apiRequest, {
      url: "/prepayment_event/treatment/",
      method: "DELETE",
      data: {
        treatment_id: payload,
      },
    });
  } catch (error) {}
}

function* toggleExternalAd({ payload }) {
  const { id, checked } = payload;
  try {
    yield call(apiRequest, {
      url: "/prepayment_event/external_ad/",
      method: "PUT",
      data: { prepayment_event_id: id, is_external_ad_agreement: checked },
    });

    yield put(prepaymentAction.toggleExternalAdSuccess(payload));
  } catch (error) {}
}

export function* async() {
  yield takeLatest(prepaymentAction.getEventCount, getEventCount);

  yield takeLatest(prepaymentAction.getEvents, getEvents);
  yield takeLatest(prepaymentAction.getEvent, getEvent);
  yield takeLatest(prepaymentAction.getOptions, getOptions);
  yield takeLatest(prepaymentAction.stopEvent, stopEvent);
  yield takeLatest(prepaymentAction.postEvent, postEvent);
  yield takeLatest(prepaymentAction.deleteEvent, deleteEvent);

  yield takeLatest(prepaymentAction.getSubCategories, getSubCategories);
  yield takeLatest(prepaymentAction.getFilters, getFilters);
  yield takeLatest(prepaymentAction.getFilterAll, getFilterAll);

  yield takeLatest(prepaymentAction.getTreatmentList, getTreatmentList);
  yield takeLatest(prepaymentAction.getTreatments, getTreatments);
  yield takeLatest(prepaymentAction.postTreatment, postTreatment);
  yield takeLatest(prepaymentAction.deleteTreatment, deleteTreatment);

  yield takeLatest(prepaymentAction.toggleExternalAd, toggleExternalAd);
}
