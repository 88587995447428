import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  row-gap: 6px;
  padding: 16px;
  border-radius: 6px;

  font-size: 11px;
  font-weight: 400;
  line-height: 150%;
  width: 100%;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.grey200.main};
    background: ${theme.palette.grey100.main};
    color: ${theme.palette.grey700.main};

    ul {
      padding-left: 20px;
      margin: 0;
      color: ${theme.palette.grey600.main};
    }

    & a {
      color: ${theme.palette.grey600.main};
      font-size: 13px;
      font-weight: 400;
      line-height: 150%;
      text-decoration: underline;
    }
  `}
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
`;

const PageDescription = ({ title, children, ...props }) => (
  <Wrapper {...props}>
    <Title>{title}</Title>
    {children}
  </Wrapper>
);

PageDescription.propTypes = {
  title: PropTypes.string,
};

export default PageDescription;
