import { createSlice } from "@reduxjs/toolkit";

export const name = "loading";
const slice = createSlice({
  name,
  initialState: {
    count: 0,
    description: "",
    errorMsg: [],
  },
  reducers: {
    pending: state => {
      state.count++;
    },
    pendingDescription: (state, { payload }) => {
      state.count++;
      state.description = payload;
    },
    success: state => {
      state.count--;
    },
    successDescription: state => {
      state.count--;
      state.description = "";
    },
    fail: state => {
      state.count--;
      // 마지막 fail에 error alert
      if (state.count === 0 && state.errorMsg.length > 0) {
        state.errorMsg.map(v => alert(v));
        state.errorMsg = [];
      }
    },
    failDescription: state => {
      state.count--;
      state.description = "";
      // 마지막 fail에 error alert
      if (state.count === 0 && state.errorMsg.length > 0) {
        state.errorMsg.map(v => alert(v));
        state.errorMsg = [];
      }
    },
    setFailMessage: (state, action) => {
      if (state.errorMsg.indexOf(action.payload) === -1) {
        state.errorMsg.push(action.payload);
      }
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
