import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  list: [],
};

export const name = "message_list";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getList: () => {},
    open: (state, action) => {
      state.show = true;
      state.list = action.payload.map(item => ({
        ...item,
        sphone: item.sphone || item.sender_phone,
        dphone: item.dphone || item.receiver_phone,
      }));
    },
    close: () => {
      return initialState;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
