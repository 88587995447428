import { createSlice } from "@reduxjs/toolkit";

export const name = "profile";
const slice = createSlice({
  name,
  initialState: {
    info: null,
  },
  reducers: {
    getProfile: () => {},
    setProfile: (state, action) => {
      state.info = { ...state.info, ...action.payload };
    },
    editProfile: () => {},
    deleteProfile: () => {},
    getMoney: () => {},
    changeMoneyProfile: (state, action) => {
      state.info.money = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
