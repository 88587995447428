import React from "react";
import BaseTooltip from "./base";

const Tooltip = () => (
  <>
    <BaseTooltip id="rt-primary" />
    <BaseTooltip id="rt-babi" />
    <BaseTooltip id="rt-tint" />
  </>
);

export default React.memo(Tooltip);
