import React from "react";
import styled from "styled-components";
import Modal, { Title } from "components/modals/base/Modal";
import { FlexEnd } from "components/common/Flex";
import { Button } from "@mui/material";

const ModalContent = styled.div`
  padding: 32px 32px 36px;
  button {
    width: 100%;
  }
`;

const Body = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #484760;
  text-align: center;
  margin-top: 12px;
`;

const ConfirmModal = ({ options, close }) => {
  const { title, body, text, button, width } = options;

  return (
    <Modal width={width || (title ? 455 : 334)} close={close} isBlockBackdropClick>
      <ModalContent>
        {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
        {body && <Body dangerouslySetInnerHTML={{ __html: body }} />}
        {text && <Body dangerouslySetInnerHTML={{ __html: text }} />}
        <FlexEnd style={{ marginTop: 24 }}>
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            onClick={button?.cancel?.onClick ? button.cancel.onClick : () => close("cancel")}
          >
            {button?.cancel?.text || "취소"}
          </Button>
          <Button
            size="large"
            variant="contained"
            color={button?.color || button?.confirm?.color || "primary"}
            onClick={() => {
              button?.onClick && button.onClick();
              button?.confirm?.onClick && button?.confirm.onClick();
              close("confirm");
            }}
          >
            {button?.text || button?.confirm?.text || "확인"}
          </Button>
        </FlexEnd>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
