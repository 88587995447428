import React from "react";
import styled from "styled-components";
import { Button, Typography } from "@mui/material";
import { CustomEventIcon } from "stories/v2/icons";
import useChallenge from "hooks/useChallenge";
import moment from "moment";

const BannerWrapper = styled.div`
  display: flex;
  padding: 2px 14px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 6px;
  background: linear-gradient(90deg, #5336ff -0.29%, #ff528d 100%);
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Banner = ({ hideButton, ...props }) => {
  const { list, openRewardModal } = useChallenge();
  const endDate = list?.find(item => item.id === 1)?.end_date;

  return (
    Boolean(endDate) &&
    moment().isSameOrBefore(endDate) && (
      <BannerWrapper onClick={() => openRewardModal({ hideButton })} {...props}>
        <TextWrapper>
          <CustomEventIcon color="white" />
          <Typography variant="h4" color="text.white">
            현재 기능코 카테고리의 혜택 챌린지가 진행 중입니다.
          </Typography>
        </TextWrapper>
        <Button size="small" color="white">
          챌린지 알아보기
        </Button>
      </BannerWrapper>
    )
  );
};

export default Banner;
