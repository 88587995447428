import { createSlice } from "@reduxjs/toolkit";
import { listInitialState } from "./common/initialState";

const initialState = {
  ...listInitialState,
  monthly: {},
};

export const name = "prepaymentSettlement";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getList: () => {},
    setList: (state, { payload }) => {
      state.list = [{ ...payload.accumulated, isSumary: true }, ...payload.daily_list];
      state.paginate = payload.paginate;
    },
    getMonthly: () => {},
    setMonthly: (state, { payload }) => {
      state.monthly = payload;
    },
    exportExcel: () => {},
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
