import MuiFormControlLabel from "@mui/material/FormControlLabel";

const FormControlLabel = ({ label, control, sx, ...props }) => {
  return (
    <MuiFormControlLabel
      sx={{
        gap: "6px",
        marginLeft: "0px",
        marginRight: "16px",
        fontSize: "13px",
        lineHeight: "19.5px",
        fontWeight: 400,
        color: "grey800.main",
        "&.Mui-disabled": {
          color: "grey500.main",
        },
        ...sx,
      }}
      {...{ label, control, ...props }}
    />
  );
};

export default FormControlLabel;
