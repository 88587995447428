import { put, call, takeLatest } from "redux-saga/effects";
import { apiRequest } from "../../server_url";
import { action as sidebarAction } from "../../modules/common/sidebar";

function* getMenu() {
  try {
    const result = yield call(apiRequest, {
      url: "/home/menu/",
      method: "GET",
    });

    yield put(sidebarAction.setMenu(result.data));
  } catch (err) {}
}

function* getPushPopupCount() {
  try {
    const result = yield call(apiRequest, {
      url: "/advertise/unchecked/count/",
      method: "GET",
    });

    yield put(sidebarAction.setPushPopupCount(result.data?.unchecked_count));
  } catch (err) {}
}

export function* async() {
  yield takeLatest(sidebarAction.getMenu, getMenu);
  yield takeLatest(sidebarAction.getPushPopupCount, getPushPopupCount);
}
