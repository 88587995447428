import { createSlice } from "@reduxjs/toolkit";
import { stringToPrice } from "helpers/format";
import { arrayToObject } from "helpers/object";
import { getDiscountRate } from "helpers/price";

export const initialCustomBannerState = {
  layoutType: 1,
  color: "#3C3988",
  title: "",
  checkedDescription: true,
  description: "",
  checkedPrice: true,
  price: 0,
  isMultipleOptions: false,
};

export const initialSubTreatmentsState = { id: null, info: null, price: null };
export const initialTreatmentsState = [
  {
    sub_category: null,
    sub_category_filter: null,
    sub_category_filter_name: null,
    // price: null,
    detail: [],
  },
];

export const initialOptionsState = [
  {
    option_id: null,
    name: "",
    price: 0, // 상품가격
    discounted_price: 0, // 최종가
    rate: 0,
    treatments: [
      {
        treatment_id: null,
        amount: null,
      },
    ],
  },
];

const initialDetailFeatureState = {
  features: {
    new_product: {
      type: "event",
      feature: "new_product",
      checked: 0,
    },
    cleansing: {
      type: "event",
      feature: "cleansing",
      checked: 0,
    },
    genuine: {
      type: "event",
      feature: "genuine",
      checked: 0,
    },
    anesthesia: {
      type: "event",
      feature: "anesthesia",
      text1: "",
      checked: 0,
    },
    free_retouch: {
      type: "management",
      feature: "free_retouch",
      text1: "",
      text2: "",
      checked: 0,
    },
    gift_service: {
      type: "management",
      feature: "gift_service",
      text1: "",
      text2: "",
      checked: 0,
    },
    check_surgery: {
      type: "management",
      feature: "check_surgery",
      text1: "",
      checked: 0,
    },
    feature: [
      // {
      //   type: "hospital",
      //   feature: "feature",
      //   text1: "",
      //   text2: "",
      // },
    ],
  },
};

export const initialDetailState = {
  id: 0,
  name: "",
  description: "",
  target: "",
  side_effects: "",
  hospital_name: "",
  hospital_location: "",
  sub_category1: 0,
  sub_category2: 0,
  sub_category3: 0,
  sub_category_filter1: 0,
  sub_category_filter2: 0,
  sub_category_filter3: 0,
  start_date: "",
  end_date: "",
  img: "",
  timg: "",
  maintenanceFeatures: [],
  hospitalFeatures: [],
  options: [...initialOptionsState],
  ...initialDetailFeatureState,
  hasCustomBannerImage: false,
  hasCustomEventImage: false,
  // customBannerImage: { ...initialCustomBannerState },
  // customEventImage: { list: [], description: "" },
};

const initialState = {
  events: [],
  pagination: {
    total_page: 1,
    page: 1,
  },
  allEvents: [],
  pageLimit: localStorage.getItem("prepayment_pageLimit") || 10,

  isSetEvents: false,
  eventCount: null,
  summary: {},
  detail: initialDetailState,
  detail_origin: initialDetailState,
  detail_option: {
    options: [...initialOptionsState],
  },
  treatments: initialTreatmentsState,
  // 카테고리 정보
  treatmentList: [],
  sub_categories: [],
  filters: [],
};

export const name = "prepayment";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getEventCount: () => {},
    setEventCount: (state, { payload }) => {
      state.eventCount = payload.prepayment_event_count;
    },

    getEvents: () => {},
    setEvents: (state, { payload }) => {
      localStorage.setItem("prepayment_pageLimit", state.pageLimit);

      const { event_list, summary_dict } = payload;
      state.events = event_list?.slice(0, state.pageLimit);
      state.pagination = {
        total_page: Math.ceil(event_list?.length / state.pageLimit),
        page_limit: state.pageLimit,
        page: 1,
      };

      state.allEvents = event_list;
      state.summary = summary_dict;
      state.isSetEvents = true;
    },
    setPage: (state, { payload }) => {
      state.events = state.allEvents?.slice(
        (payload - 1) * state.pageLimit,
        payload * state.pageLimit
      );
      state.pagination.page = payload;
    },
    setPageLimit: (state, { payload }) => {
      state.pageLimit = payload;
    },

    getEvent: () => {},
    setEvent: (state, { payload }) => {
      let features = [];
      if (!payload.isLoadSaveData && payload.features) {
        features = arrayToObject(
          [
            ...(payload.features.filter(item => item.feature !== "feature") || []).map(item => {
              if (item.feature === "anesthesia") {
                return {
                  ...item,
                  checked: 1,
                  text1: item?.text1 ? stringToPrice(item.text1) : 0,
                };
              }
              return { ...item, checked: 1 };
            }),
          ],
          "feature"
        );
      }

      const setInitialData = {
        ...initialDetailState,
        key: new Date().getTime(),
        ...payload,
        features: payload.isLoadSaveData
          ? { ...payload.features }
          : {
              ...initialDetailFeatureState.features,
              ...features,
              feature: payload?.features?.filter(item => item.feature === "feature") || [],
            },
        options: payload.options?.length
          ? payload.options.map((option, index) => ({
              ...option,
              ...(!option.option_id ? { option_id: `${new Date().getTime()}_${index}` } : {}),
              treatments: option.treatments.map(item => ({
                ...item,
                id_origin: item.treatment_id,
              })),
              rate: getDiscountRate(option.price, option.discounted_price, 1),
            }))
          : [...initialOptionsState],
        ...(payload.custom_timg
          ? {
              hasCustomBannerImage: true,
              customBannerImage: payload?.customBannerImage
                ? { ...payload?.customBannerImage }
                : {
                    layoutType: payload.custom_timg.style,
                    color: payload.custom_timg.background,
                    title: payload.custom_timg.title,
                    checkedDescription: Boolean(payload.custom_timg.description),
                    description: payload.custom_timg.description,
                    checkedPrice: Boolean(payload.custom_timg.price),
                    price: payload.custom_timg.price,
                    isMultipleOptions: Boolean(payload.options?.length > 1),
                  },
            }
          : {}),
        ...(payload.custom_img
          ? {
              hasCustomEventImage: true,
              customEventImage: payload?.customEventImage
                ? { ...payload?.customEventImage }
                : {
                    list: [1, 2, 3]
                      .map(index =>
                        payload.custom_img[`title${index}`]
                          ? {
                              title: payload.custom_img[`title${index}`],
                              description: payload.custom_img[`description${index}`],
                            }
                          : null
                      )
                      .filter(Boolean),
                    description: payload.custom_img.additional_description,
                  },
            }
          : {}),
      };

      state.detail = { ...setInitialData };
      if (payload.dates) {
        state.detail.start_date = payload.dates.start_date;
        state.detail.end_date = payload.dates.end_date;
      }

      if (!payload.isLoadSaveData) {
        state.detail_origin = { ...setInitialData };

        if (payload.dates) {
          state.detail_origin.start_date = payload.dates.start_date;
          state.detail_origin.end_date = payload.dates.end_date;
        }
      }
    },
    setDatesOrigin: (state, { payload }) => {
      state.detail_origin.start_date = payload.startDate;
      state.detail_origin.end_date = payload.endDate;
    },
    setInitialDetail: state => {
      state.detail = initialDetailState;
      state.detail_origin = initialDetailState;
    },
    postEvent: () => {},
    stopEvent: () => {},
    stopEventSuccess: (state, { payload }) => {
      const event = state.events.find(event => event.id === payload.id);
      if (event) {
        event.status = 1;
        event.scmt = payload.scmt;
      }
    },
    deleteEvent: () => {},
    deleteEventSuccess: (state, { payload }) => {
      state.events = state.events.filter(event => event.id !== payload);
    },

    getOptions: () => {},
    setOptions: (state, { payload }) => {
      state.detail_option.id = payload.id;
      state.detail_option.key = new Date().getTime();
      state.detail_option.options = payload.options?.length
        ? payload.options.map(option => ({
            ...option,
            treatments: option.treatments.map(item => ({
              ...item,
              id_origin: item.treatment_id,
            })),
          }))
        : [...initialOptionsState];
    },

    setSubCategory: (state, { payload }) => {
      const { index, category_id } = payload;
      state.detail[`sub_category${index}`] = parseInt(category_id);
      state.detail[`sub_category_filter${index}`] = 0;
    },
    setFilter: (state, { payload }) => {
      const { index, id } = payload;
      state.detail[`sub_category_filter${index}`] = parseInt(id);
    },

    setState: (state, { payload }) => {
      state.detail[payload.name] = payload.value;
    },

    getSubCategories: () => {},
    setSubCategories: (state, { payload }) => {
      state.sub_categories = payload;
    },
    getFilters: () => {},
    setFilters: (state, { payload }) => {
      state.filters[payload.id] = payload.list;
    },
    getFilterAll: () => {},
    setFilterAll: (state, { payload }) => {
      Object.keys(payload).forEach(key => {
        state.filters[parseInt(key)] = [
          ...payload[key].map(item => ({
            ...item,
            name: (item.parent_id ? "기타: " : "") + item.name,
          })),
        ];
      });
    },
    setFeature: (state, { payload }) => {
      const { name, checked, text1, text2 } = payload;
      if ([1, 0].includes(checked)) state.detail.features[name].checked = checked;
      if (text1 !== undefined) state.detail.features[name].text1 = text1;
      if (text2 !== undefined) state.detail.features[name].text2 = text2;

      if (
        (text1 || text2) &&
        !state.detail.features[name].text1 &&
        !state.detail.features[name].text2
      )
        state.detail.features[name].checked = 0;

      if (checked === 0) {
        state.detail.features[name].text1 = "";
        state.detail.features[name].text2 = "";
      }
    },
    setHospitalFeature: (state, { payload }) => {
      state.detail.features.feature = payload;
    },

    getTreatmentList: () => {},
    setTreatmentList: (state, { payload }) => {
      state.treatmentList = payload.map(item => ({
        ...item,
        name:
          (item.sub_category_filter_name ||
            state.filters[item.sub_category]?.find(filter => filter.id === item.sub_category_filter)
              ?.name ||
            "") + (item.info ? ` ${item.info}` : ""),
        key: item.id,
      }));
    },
    getTreatments: () => {},
    setTreatments: (state, { payload }) => {
      if (payload?.length) {
        state.treatments = payload.map(item => {
          const newFilter =
            item.sub_category_filter_name &&
            state.filters[item.sub_category].find(
              filter => filter.name === "기타: " + item.sub_category_filter_name && filter.parent_id
            );

          if (newFilter) {
            item = {
              ...item,
              sub_category_filter_name: null,
              sub_category_filter: newFilter.id,
            };
          }

          return item.detail.length === 1 && !item.detail[0].info
            ? { ...item, id: item.detail[0].id, price: item.detail[0].price, detail: [] }
            : { ...item, id: item.detail[0].id };
        });
      }
    },
    postTreatment: () => {},
    deleteTreatment: () => {},

    toggleExternalAd: () => {},
    toggleExternalAdSuccess: (state, { payload }) => {
      const { id, checked } = payload;
      const event = state.events.find(event => event.id === parseInt(id));
      if (event) {
        event.external_ad = checked;
      }
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
