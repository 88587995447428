import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { colorNames } from "theme/palette";

export const fontSizeProps = ["inherit", "xsmall", "small", "medium", "large"];
export const colorProps = [
  "primary",
  "secondary",
  "tertiary",
  "success",
  "error",
  "warning",
  "info",
  ...colorNames,
];

export const Icon = ({ svgIcon, fontSize, ...props }) => {
  return (
    <SvgIcon {...props} fontSize={fontSize}>
      {svgIcon}
    </SvgIcon>
  );
};

Icon.propTypes = {
  svgIcon: PropTypes.node.isRequired,
  fontSize: PropTypes.oneOf(fontSizeProps),
  color: PropTypes.string,
};

Icon.defaultProps = {
  svgIcon: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0002 2.66653C14.4816 2.66653 16.6191 3.79514 18.1522 5.84997C20.3224 7.06165 21.3335 9.38959 21.3335 12.0003C21.3335 14.6101 20.3224 16.9381 18.1522 18.1524C16.6191 20.2046 14.4816 21.3332 12.0002 21.3332C9.51883 21.3332 7.38039 20.2046 5.84826 18.1524C3.67798 16.9381 2.66687 14.6101 2.66687 12.0003C2.66687 9.38959 3.67798 7.06165 5.84826 5.84997C7.38039 3.79514 9.51883 2.66653 12.0002 2.66653ZM10.7801 11.3646H12.7634C13.337 11.3594 13.7995 10.9979 13.7995 10.2447C13.7995 9.48518 13.3308 9.12057 12.7508 9.12057H10.7801V11.3646ZM12.7508 14.8782V14.874C13.3308 14.874 13.7995 14.5126 13.7995 13.753C13.7995 12.9998 13.337 12.6362 12.7634 12.6321H10.7801V14.8782H12.7508ZM10.7677 7.85172H12.7384C14.0147 7.85172 15.0477 8.78412 15.0477 10.2445C15.0477 11.0481 14.7321 11.5164 14.236 12.0004C14.7321 12.4855 15.0477 12.9538 15.0477 13.7583C15.0477 15.2156 14.0178 16.1459 12.7426 16.148H10.7677H9.51129V14.8835V9.1204V7.85172H10.7677Z"
    />
  ),
  fontSize: "medium",
};
