export default {
  MuiSvgIcon: {
    variants: [
      {
        props: { fontSize: "xsmall" },
        style: {
          fontSize: "16px",
        },
      },
      {
        props: { fontSize: "small" },
        style: {
          fontSize: "20px",
        },
      },
      {
        props: { fontSize: "medium" },
        style: {
          fontSize: "24px",
        },
      },
      {
        props: { fontSize: "large" },
        style: {
          fontSize: "36px",
        },
      },
    ],
  },
};
