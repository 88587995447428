import MuiSnackbar from "@mui/material/Snackbar";
import PropTypes from "prop-types";
import AlertContent from "./AlertContent";

export const typeProps = ["error", "warning", "info", "success"];

const Alert = ({ open, onClose, ...props }) => {
  return (
    Boolean(props?.text) && (
      <>
        <MuiSnackbar
          open={open}
          onClose={onClose}
          autoHideDuration={props.hasClose ? null : 3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <div>
            <AlertContent {...props} onClose={onClose} />
          </div>
        </MuiSnackbar>
      </>
    )
  );
};

Alert.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(typeProps),
  hasClose: PropTypes.bool,
};

Alert.defaultProps = {
  open: false,
  type: "info",
  hasClose: false,
};

export default Alert;
