import { put, call, takeLatest } from "redux-saga/effects";
import { action as chargeLastAction } from "../modules/chargeLast";
import { action as clientAction } from "../modules/client";
import { apiRequest } from "../server_url";

function* getChargeLast() {
  try {
    const response = yield call(apiRequest, {
      url: "/charge/last/",
      method: "GET",
    });
    yield put(chargeLastAction.setChargeLast(response.data[0]));
  } catch (err) {}
}

function* applyChargeLast({ payload }) {
  const { data, callback } = payload;
  try {
    yield call(apiRequest, {
      url: "/charge/apply/",
      method: "POST",
      data,
    });
    yield put(chargeLastAction.getChargeLast());
    yield put(clientAction.getClientInfo());
    callback && callback();
  } catch (err) {}
}

function* cancelChargeLast({ payload }) {
  const { data, callback } = payload;
  try {
    yield call(apiRequest, {
      url: "/charge/cancel/",
      method: "POST",
      data,
    });
    yield put(chargeLastAction.getChargeLast());
    yield put(clientAction.getClientInfo());
    callback && callback();
  } catch (err) {}
}

function* createVacs(action) {
  const { corp_name, onAction } = action.payload;
  try {
    yield call(apiRequest, {
      url: "/charge/create/vacs/",
      method: "POST",
      data: { corp_name: corp_name },
    });
    onAction();
  } catch (err) {}
}

export function* chargeListAsync() {
  yield takeLatest(chargeLastAction.getChargeLast, getChargeLast);
  yield takeLatest(chargeLastAction.applyChargeLast, applyChargeLast);
  yield takeLatest(chargeLastAction.cancelChargeLast, cancelChargeLast);
  yield takeLatest(chargeLastAction.createVacs, createVacs);
}
