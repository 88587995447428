import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Base from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useState } from "react";
import { palette, typography } from "../../theme";

export const typeProps = ["error", "warning", "info", "success"];

const StyledComponent = styled(Base)(() => ({
  marginRight: 24,
  width: 400,
  alignItems: "center",
  padding: "12px 14px",
  color: "#ECECEF",
  fontSize: "13px",
  fontWeight: 500,
  lineHeight: "24px",
  borderRadius: "6px",
  background: "#373649",
  boxShadow:
    "0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 1px 10px 0px rgba(0, 0, 0, 0.06), 0px 4px 5px 0px rgba(0, 0, 0, 0.03)",
  "& .MuiAlert-icon": {
    padding: "3px",
    fontSize: "18px",
    marginRight: 8,
  },
  "& .MuiAlert-message": {
    padding: 0,
  },
  "& .MuiAlert-action": {
    padding: "0px 5px 0px 8px",
    button: {
      padding: 0,
      width: 24,
      height: 24,
    },
  },
  "&.MuiAlert-standardError": {
    color: palette.error.main,
  },
}));

const CustomThemeProvider = ({ theme, type, text, hasClose, onClose, ...props }) => {
  const [open, setOpen] = useState(true);

  return (
    Boolean(text) && (
      <ThemeProvider theme={theme}>
        <Collapse in={open}>
          <StyledComponent
            severity={type}
            color={type}
            onClose={
              hasClose
                ? () => {
                    onClose && onClose();
                    setOpen(false);
                  }
                : null
            }
            iconMapping={{
              error: <ErrorRoundedIcon fontSize="inherit" />,
              warning: <WarningRoundedIcon fontSize="inherit" />,
              info: <InfoRoundedIcon fontSize="inherit" />,
              success: <CheckCircleRoundedIcon fontSize="inherit" />,
            }}
            {...props}
          >
            {text}
          </StyledComponent>
        </Collapse>
      </ThemeProvider>
    )
  );
};

const AlertContent = ({ children, ...props }) => (
  <CustomThemeProvider
    theme={createTheme({
      palette: {
        ...palette,
        warning: palette.yellow200,
        success: palette.green300,
      },
      typography,
    })}
    {...props}
  >
    {children}
  </CustomThemeProvider>
);

AlertContent.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(typeProps),
  hasClose: PropTypes.bool,
};

AlertContent.defaultProps = {
  type: "info",
  hasClose: false,
};

export default AlertContent;
