import { lazy } from "react";

export const retryLazy = (componentImport, name = "") =>
  lazy(
    () =>
      new Promise((resolve, reject) => {
        const refreshedName = `refreshed_v${process.env.REACT_APP_VERSION}${name ?? `_${name}`}`;
        const pageAlreadyRefreshed = JSON.parse(localStorage.getItem(refreshedName) || "false");

        // try to import the component
        componentImport()
          .then(component => {
            resolve(component);
          })
          .catch(error => {
            if (!pageAlreadyRefreshed) {
              localStorage.setItem(refreshedName, "true");
              return window.location.reload();
            }
            reject(error);
          });
      })
  );
