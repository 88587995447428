import React, { useEffect, useState } from "react";
import RSelect from "react-select";

const Select = ({
  value,
  error,
  disabled,
  optionLabel,
  optionValue,
  inputRef,
  searchInput,
  noOptionsMessage = "선택 가능한 값이 없습니다.",
  ...props
}) => {
  const [defaultValue, setDefaultValue] = useState();

  useEffect(() => {
    setDefaultValue(
      props.options?.length
        ? props.isArrayValue
          ? value
          : props.isMulti
          ? props.options.filter(option => value?.includes(option[optionValue]))
          : props.options.find(option => option[optionValue] === value)
        : props.isArrayValue
        ? []
        : ""
    );
  }, [props.isArrayValue, props.isMulti, optionValue, props.options, value]);

  return (
    <RSelect
      ref={inputRef}
      className={`react-select ${error ? "error" : ""}`}
      isSearchable
      noOptionsMessage={() => noOptionsMessage}
      styles={{
        control: base => ({
          ...base,
          textAlign: props.textAlign || "center",
          minWidth: props.minWidth,
          minHeight: props.height || "36px",
          height: props.height || "auto",
          borderColor: "#ebebeb",
        }),
        clearIndicator: base => ({
          ...base,
          padding: "0px 8px",
        }),
        input: base => ({
          ...base,
          color: "#484760",
        }),
        singleValue: base => ({
          ...base,
          color: "#484760",
        }),
        placeholder: base => ({
          ...base,
          color: "#aaaaaa",
        }),
        menu: base => ({
          ...base,
          zIndex: 9999,
          color: "#484760",
          marginBottom: "15px",
          marginTop: "5px",
        }),
        option: (base, state) => ({
          ...base,
          color: state?.data?.isDisabled ? "#A3A3AF" : "#484760",
        }),
        indicatorSeparator: base => ({
          ...base,
          display: searchInput && "none",
        }),
        dropdownIndicator: base => ({
          ...base,
          padding: 4,
          display: searchInput && "none",
        }),
      }}
      menuPortalTarget={document.querySelector(".custom-react-material-modal")}
      menuPosition={document.querySelector(".custom-react-material-modal") ? "fixed" : ""}
      menuShouldBlockScroll={true}
      {...props}
      isDisabled={disabled}
      getOptionLabel={option => option[optionLabel]}
      getOptionValue={option => option[optionValue]}
      value={!value ? null : defaultValue}
      onChange={selected => {
        let value = selected;
        if (value && !props.isArrayValue) {
          value = Array.isArray(selected)
            ? selected.map(v => v[optionValue])
            : selected[optionValue];
        }
        props.onChange(value);
      }}
    />
  );
};

Select.defaultProps = {
  optionLabel: "name",
  optionValue: "value",
  placeholder: "선택하세요.",
  disabled: false,
};

export default Select;
