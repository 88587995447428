import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  doctors: [],
  is_on: false,
};

export const name = "doctorAsk";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getDoctorAsk: () => {},
    setDoctorAsk: (state, action) => {
      let index = 1;
      state.doctors = action.payload.map(doctor => ({
        ...doctor,
        index: doctor.status === 0 && index++,
      }));
    },

    // 의사 상담 전체 설정
    getOverallDoctorCounsel: () => {},
    toggleOverallDoctorCounsel: () => {},
    setOverallDoctorCounsel: (state, action) => {
      state.is_on = action.payload;
    },

    // 의사 상담 의사별 설정
    toggleDoctorCounsel: () => {},

    changeDoctorAskOrder: (state, action) => {
      state.doctors = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
