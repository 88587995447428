import { useQuery } from "@tanstack/react-query";
import { apiRequest } from "server_url";
import useModal from "./common/useModal";
import RewardModal from "components/pages/main/challengeSection/modal/RewardModal";
import { useCallback } from "react";

const useChallenge = () => {
  const modal = useModal();
  const openRewardModal = useCallback(
    ({ hideButton = false }) => {
      modal.custom({ component: RewardModal, options: { hideButton } });
    },
    [modal]
  );

  const rewards = {
    [-1]: "대상 아님",
    0: "미설정",
    1: "이벤트 상위 노출",
    2: "광고소진금 환불",
    3: "기획전 노출",
  };

  const { data: list } = useQuery(
    ["challenge", "list"],
    () =>
      apiRequest({
        url: "/home/call_to_action/challenge",
        method: "GET",
        errorAlert: false,
      }),
    {
      select: data => data?.data,
    }
  );

  return { rewards, list, openRewardModal };
};

export default useChallenge;
