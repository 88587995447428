import { put, call, takeLatest } from "redux-saga/effects";
import { apiRequest } from "server_url";
import { action } from "modules/prepaymentQna";

function* getList({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/qna/list/",
      method: "GET",
      params: {
        start_date: payload?.startDate,
        end_date: payload?.endDate,
        keyword: payload?.keyword,
        category: payload?.category,
        status: payload?.status,
        page: payload?.page,
        page_offset: 15,
      },
    });

    yield put(action.setList(response.data));
  } catch (error) {}
}

function* putAnswer({ payload }) {
  const { data, errorCallback } = payload;
  try {
    yield call(apiRequest, {
      url: "/prepayment_event/qna/",
      method: "PUT",
      data,
      errorAlert: false,
    });

    yield put(action.setAnswer({ ...data, status: 1 }));
    yield put(action.getNewQuestionCount());
  } catch (error) {
    errorCallback();
  }
}

function* deleteAnswer({ payload }) {
  try {
    yield call(apiRequest, {
      url: "/prepayment_event/qna/",
      method: "DELETE",
      data: payload,
    });

    yield put(action.setAnswer({ ...payload, status: 0 }));
    yield put(action.getNewQuestionCount());
  } catch (error) {}
}

function* getNewQuestionCount() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/qna/new_question/count/",
      method: "GET",
    });

    yield put(action.setNewQuestionCount(response.data.new_question_count));
  } catch (error) {}
}

export function* async() {
  yield takeLatest(action.getList, getList);

  yield takeLatest(action.putAnswer, putAnswer);
  yield takeLatest(action.deleteAnswer, deleteAnswer);
  yield takeLatest(action.getNewQuestionCount, getNewQuestionCount);
}
