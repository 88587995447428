import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { CustomRequiredMarkIcon } from "../../..";

const Label = ({ children, isRequired }) => {
  return (
    <Typography variant="subtitle2" sx={{ paddingLeft: "2px", height: "18px" }}>
      {children}
      {isRequired && <CustomRequiredMarkIcon color="primary" fontSize="xsmall" />}
    </Typography>
  );
};

Label.propTypes = {
  isRequired: PropTypes.bool,
};

Label.defaultProps = {
  isRequired: false,
};

export default Label;
