import { END, eventChannel } from "redux-saga";
import { call, cancel, cancelled, fork, put, take, takeLatest } from "redux-saga/effects";
import { action as timerAction } from "../../modules/common/timer";

function decrease(seconds) {
  return eventChannel(emitter => {
    const interval = setInterval(() => {
      seconds -= 1;
      if (seconds > 0) {
        emitter(seconds);
      } else {
        emitter(END);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
}

function* timerTask(startSeconds) {
  const channel = yield call(decrease, startSeconds);

  try {
    while (true) {
      let seconds = yield take(channel);
      yield put(timerAction.setSeconds(seconds));
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    } else {
      yield put(timerAction.timeOver());
    }
  }
}

function* timerWatch() {
  while (true) {
    let { payload: startSeconds } = yield take(timerAction.watch);
    const task = yield fork(timerTask, startSeconds);

    if (yield take([timerAction.stop, timerAction.timeOver])) {
      yield cancel(task);
    }
  }
}

function* start(action) {
  yield put(timerAction.stop());
  yield put(timerAction.watch(action.payload));
}

export function* timerAsync() {
  yield takeLatest(timerAction.start, start);
  yield timerWatch();
}
