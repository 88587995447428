import { createSlice } from "@reduxjs/toolkit";

export const name = "client";
const slice = createSlice({
  name,
  initialState: {
    info: null,
    prepaymentAgree: null,
  },
  reducers: {
    getClientInfo: () => {},
    postClientInfo: () => {},
    setClientInfo: (state, action) => {
      state.info = action.payload;
    },
    getPrepaymentAgree: () => {},
    setPrepaymentAgree: (state, { payload }) => {
      state.prepaymentAgree = payload;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
