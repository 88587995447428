import { put, call, takeLatest, select } from "redux-saga/effects";
import { apiRequest } from "../server_url";
import { action as loginAction } from "../modules/login";
import { action as authPhoneAction } from "../modules/modal/authPhone";
import isSet from "../helpers/validate";

function* login(action) {
  try {
    const { id, password } = action.payload;
    const response = yield call(apiRequest, {
      url: "/login/",
      method: "POST",
      data: {
        id: id,
        pw: password,
      },
    });

    let { token, login_phone } = response.data;
    if (isSet(login_phone)) {
      yield put(authPhoneAction.open({ id, password, login_phone }));
    } else {
      yield put(loginAction.loginSuccess(token));
    }
  } catch (error) {}
}

const authPhoneState = state => state.authPhone;
function* loginWithSMS(action) {
  try {
    const { id, password } = yield select(authPhoneState);
    let response = yield call(apiRequest, {
      url: "/login/with/sms/",
      method: "POST",
      data: {
        id: id,
        pw: password,
        auth: action.payload,
      },
    });

    yield put(loginAction.loginSuccess(response.data.token));
  } catch (error) {}
}

function* postAuthNumber() {
  try {
    const { id, password } = yield select(authPhoneState);
    yield call(apiRequest, {
      url: "/login/sms/auth/",
      method: "POST",
      data: {
        id: id,
        pw: password,
      },
    });
  } catch (error) {}
}

export function* loginAsync() {
  yield takeLatest(loginAction.login, login);
  yield takeLatest(loginAction.loginWithSMS, loginWithSMS);
  yield takeLatest(authPhoneAction.postAuthNumber, postAuthNumber);
}
