import { Modal as MuiModal } from "@mui/material";
import Tooltip from "components/tooltip";
import React from "react";
import styled, { css } from "styled-components";

const CustomModal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: calc(100% - 80px);
  margin-top: 40px;
  overflow-y: scroll;
  * {
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ModalPaper = styled.div`
  position: absolute;

  ${({ isScroll }) =>
    isScroll
      ? css`
          top: 0px;
        `
      : css`
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `};

  border-radius: 16px;
  outline: none;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : "376px")};
  width: ${({ width }) => (width ? `${width}px` : "376px")};
  box-shadow:
    rgb(0 0 0 / 20%) 0px 3px 5px -1px,
    rgb(0 0 0 / 14%) 0px 5px 8px 0px,
    rgb(0 0 0 / 12%) 0px 1px 14px 0px;
  background-color: #fff;

  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #484760;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  position: relative;
  color: #373649;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  min-height: 20px;
  margin-bottom: 12px;
`;

export const ScrollBody = styled.div`
  overflow-y: scroll;
  overflow-x: clip;
  max-height: calc(100vh - 186px);
`;

const Modal = ({ id, isBlockBackdropClick, close, width, minWidth, children, isScroll }) => {
  return (
    <CustomModal
      id={id}
      className="custom-react-material-modal"
      open={true}
      onClose={(event, reason) => {
        if (!isBlockBackdropClick || reason !== "backdropClick") close && close();
      }}
      disableEscapeKeyDown
    >
      <ModalPaper width={width} minWidth={minWidth} isScroll={isScroll}>
        <Tooltip />
        {children}
      </ModalPaper>
    </CustomModal>
  );
};

export default Modal;
