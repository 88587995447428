import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  major_code: [],
  liveDoctors: [],
  checkDoctors: [],
  licenceCode: "",
  categories: [],
};

export const name = "doctor";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getDoctorList: () => {},
    setDoctorList: (state, action) => {
      state.liveDoctors = action.payload.live;
      state.checkDoctors = action.payload.check;
    },
    cancelCheckupDoctor: () => {},
    updateCheckupDoctor: (state, action) => {
      state.checkDoctors = state.checkDoctors.filter(
        doctor => doctor.id !== action.payload.doctorId
      );
    },
    changeDoctorOrder: (state, action) => {
      state.liveDoctors = action.payload;
    },
    getLicenceCode: () => {},
    setLicenceCode: (state, action) => {
      state.licenceCode = action.payload;
    },
    disconnectDoctor: () => {},
    updateDisconnectDoctor: (state, action) => {
      state.liveDoctors = state.liveDoctors.filter(doctor => doctor.id !== action.payload.doctorId);
    },

    getCategories: () => {},
    setCategories: (state, { payload }) => {
      state.categories = payload;
    },
    getSpecialistCategories: () => {},
    setSpecialistCategories: (state, { payload }) => {
      state.major_code = payload;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
