import { createSlice } from "@reduxjs/toolkit";

export const name = "chargeLast";
const slice = createSlice({
  name,
  initialState: {
    last: null,
  },
  reducers: {
    getChargeLast: () => {},
    setChargeLast: (state, action) => {
      state.last = action.payload;
    },
    applyChargeLast: () => {},
    cancelChargeLast: () => {},
    createVacs: () => {},
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
