import React from "react";
import styled, { css } from "styled-components";
import { flexCenter } from "components/common/Flex";
import { backgroundIcon } from "components/common/css";
import CloseIcon from "assets/images/eventCategory/Close.svg";

// 선택한 카테고리, 대표카테고리
const SelectedCategoryBox = styled.div`
  ${flexCenter}
  flex-wrap: wrap;
  gap: 16px;
`;

const SelectedCategoryItem = styled.div`
  ${flexCenter}
  gap: 8px;

  border-radius: 6px;
  padding: 6px 10px;

  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  ${({ empty }) =>
    empty
      ? css`
          color: #7e7e8f;
          background: #ececef;
          border: 1px dashed #c8c7cf;
          border-radius: 6px;
        `
      : css`
          color: #484760;
          background: #e6e3ff;

          &:after {
            content: "";
            ${backgroundIcon(CloseIcon)}
          }
        `}
`;

const SelectedCategoriesBox = ({ categories = [], onClick }) => (
  <SelectedCategoryBox>
    {categories?.map((item, index) => (
      <SelectedCategoryItem key={index} onClick={() => onClick(item)}>
        {item.name}
      </SelectedCategoryItem>
    ))}
    {Array(3 - categories?.length)
      .fill()
      .map((item, index) => (
        <SelectedCategoryItem key={index} empty>
          카테고리를 추가해주세요
        </SelectedCategoryItem>
      ))}
  </SelectedCategoryBox>
);

export default SelectedCategoriesBox;
