import Base from "@mui/material/Chip";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { gradation, palette, typography } from "../../theme";

export const colorProps = [
  "primary",
  "secondary",
  "tertiary",
  "red",
  "green",
  "yellow",
  "gradationBoosting",
];
export const variantProps = ["contained", "outlined", "text"];
export const sizeProps = ["small", "medium", "large"];

const sizes = {
  small: {
    padding: "2px 4px",
    fontSize: "10px",
    lineHeight: "10px",
    "& .MuiChip-label": {
      display: "flex",
      alignItems: "center",
      padding: 0,
      gap: 2,
    },
    "& .MuiChip-icon, & .material-symbols-rounded": {
      width: 12,
      height: 12,
      fontSize: "12px !important",
      margin: 0,
      marginRight: 2,
    },
  },
  medium: {
    padding: "4px 6px",
    fontSize: "12px",
    lineHeight: "16px",
    "& .MuiChip-label": {
      display: "flex",
      alignItems: "center",
      padding: 0,
      gap: 4,
    },
    "& .MuiChip-icon, & .material-symbols-rounded": {
      width: 14,
      height: 14,
      fontSize: "14px !important",
      marginLeft: 0,
      marginRight: 4,
    },
  },
  large: {
    padding: "4px 6px",
    fontSize: "16px",
    lineHeight: "16px",
    "& .MuiChip-label": {
      display: "flex",
      alignItems: "center",
      padding: 0,
      gap: 4,
    },
    "& .MuiChip-icon, & .material-symbols-rounded": {
      width: 24,
      height: 24,
      fontSize: "24px !important",
      marginLeft: 0,
      marginRight: 4,
    },
  },
};

const StyledComponent = styled(Base)(({ size, _color, variant }) => ({
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 4,
  fontWeight: 600,
  width: "fit-content",
  height: "auto",

  ...sizes[size],

  "&.MuiChip-root.MuiChip-outlined": {
    background: "#fff",
    borderColor: palette.chip[_color].border,
  },

  ...(variant === "text"
    ? {
        background: "transparent",
        border: "none",
        color: palette.chip[_color].main,
      }
    : {}),

  ...(_color.includes("gradation") && {
    "&.MuiChip-root.MuiChip-outlined": {
      position: "relative",
      border: 0,
      background: gradation[_color],
      "&:before": {
        content: "''",
        position: "absolute",
        width: "calc(100% - 2px)",
        height: "calc(100% - 2px)",
        background: "#fff",
        left: "1px",
        top: "1px",
        borderRadius: "3.5px",
      },
      ".MuiChip-label": {
        padding: "1px",
        background: gradation[_color],
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        zIndex: 1,
      },
    },
    "&.MuiChip-root.MuiChip-contained": {
      background: gradation[_color],
      color: "#fff",
    },
    ...(variant === "text" && {
      background: gradation[_color],
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      zIndex: 1,
    }),
  }),
}));

const CustomThemeProvider = ({ theme, children, ...props }) => (
  <ThemeProvider theme={theme}>
    <StyledComponent
      {...props}
      disablefocusripple="true"
      disableelevation="true"
      disableripple="true"
      label={children}
    />
  </ThemeProvider>
);

const Tag = ({ color, children, size, ...props }) => (
  <CustomThemeProvider
    theme={createTheme({
      palette: {
        primary: { ...palette.chip[color] },
      },
      typography,
    })}
    size={size}
    color="primary"
    _color={color || "primary"}
    {...props}
  >
    {children}
  </CustomThemeProvider>
);

Tag.propTypes = {
  color: PropTypes.oneOf(colorProps),
  variant: PropTypes.oneOf(variantProps),
  size: PropTypes.oneOf(sizeProps),
  icon: PropTypes.node,
};

Tag.defaultProps = {
  color: "primary",
  variant: "contained",
  size: "medium",
};

export default Tag;
