import MuiInputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";
import { typography } from "../../theme";

const InputBase = ({ sx = undefined, ...props }) => {
  return (
    <MuiInputBase
      {...props}
      sx={{
        "&.Mui-disabled": {
          borderColor: "grey300.main",
          backgroundColor: "grey100.main",
        },

        "& .MuiInputBase-input": {
          display: "flex",
          alignItems: "center",
          gap: "6px",
          minHeight: "16px",
          lineHeight: "16px",
          padding: 0,

          "& .MuiSvgIcon-root": {
            fontSize: "16px",
            color: "grey500.main",
            margin: 0,
          },
          "&:focus": {
            background: "none",
          },
        },
        gap: "6px",
        borderRadius: "6px",
        border: "1px solid",
        borderColor: "grey300.main",
        background: "white",
        padding: "10px",

        height: "36px",
        minHeight: "16px",
        lineHeight: "16px",
        whiteSpace: "nowrap",

        "& input": {
          padding: 0,
          ...typography.body1,
          "&::-webkit-input-placeholder": {
            color: "grey600.main",
            opacity: 1,
          },
        },
        "& .MuiSvgIcon-root.MuiSelect-icon": {
          fontSize: "24px",
          color: "grey500.main",
        },
        "& .MuiSelect-iconStandard": {
          marginRight: "6px",
        },

        ".Mui-error": {
          backgroundColor: "red400.main",
        },

        ...sx,
      }}
    />
  );
};

InputBase.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default InputBase;
