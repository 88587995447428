import React from "react";
import { NumericFormat } from "react-number-format";
import InputBase from "./Base";

// value, defaultValue
const InputNumber = ({ onChange, suffix = undefined, ...props }) => {
  return (
    <NumericFormat
      onValueChange={values => {
        onChange && onChange(values?.floatValue);
      }}
      suffix={suffix}
      customInput={InputBase}
      thousandSeparator
      {...props}
    />
  );
};

export default InputNumber;
