import React from "react";
import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import moment from "moment";
import "moment-timezone";
import "moment/locale/ko";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

import "./assets/common.scss";
import "./assets/css/font-icons/entypo/css/entypo.css";
import "./index.scss";

import ThemeProvider from "ThemeProvider";
import "./assets/css/bootstrap.css";
import "./assets/css/neon-core.css";
import "./assets/css/neon-forms.css";
import "./assets/css/neon-theme.css";
import "./assets/css/select2.css";

// 전역적으로 한국어 설정
moment.locale("ko");
// 전역적으로 한국 시간대 설정
moment.tz.setDefault("Asia/Seoul");

if (window.location.hostname !== "localhost" && process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_VERSION,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    ignoreErrors: [
      "Non-Error exception captured with keys: config, data, headers, request, status",
    ],
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
