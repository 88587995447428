import ArrowDropDownRounded from "@mui/icons-material/ArrowDropDownRounded";
import InputBase from "@mui/material/InputBase";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import MenuItem from "../MenuItem";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    display: "flex",
    alignItems: "center",
    gap: 6,
    borderRadius: 6,
    position: "relative",
    padding: "8px 38px 8px 12px",
    fontSize: 13,
    minHeight: 20,
    maxHeight: 20,
    color: theme.palette.grey700.main,
    "&:focus": {
      borderRadius: 6,
      backgroundColor: "#fff",
    },
  },
  "& .MuiSvgIcon-root": {
    fontSize: 16,
    color: theme.palette.grey500.main,
  },
}));

const TableSelectBox = ({ value, onChange, options }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      input={<StyledInputBase />}
      IconComponent={ArrowDropDownRounded}
    >
      {options?.map(item => (
        <MenuItem key={item.value} {...item}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};

TableSelectBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default TableSelectBox;
