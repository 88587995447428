import { put, call, takeLatest, all } from "redux-saga/effects";
import { apiRequest } from "server_url";
import { action } from "modules/talkQnaModule";

function* getList({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/talk/qna/list/",
      method: "GET",
      params: {
        start_date: payload?.startDate,
        end_date: payload?.endDate,
        keyword: payload?.keyword,
        concern: payload?.category,
        status: payload?.status,
        page: payload?.page,
        page_offset: 15,
      },
    });

    yield put(action.setList(response.data));
  } catch (error) {}
}

function* checkAnswer({ payload }) {
  const { data, successCallback, errorCallback } = payload;
  try {
    yield call(apiRequest, {
      url: "/talk/qna/doctor_answer/check/",
      method: "GET",
      params: data,
      errorAlert: false,
    });

    successCallback && successCallback();
  } catch (error) {
    errorCallback(error?.response?.data?.message);
  }
}

function* putAnswer({ payload }) {
  const { data, errorCallback } = payload;
  try {
    const response = yield call(apiRequest, {
      url: "/talk/qna/doctor_answer/",
      method: data.rid ? "PUT" : "POST",
      data,
      errorAlert: false,
    });

    yield put(
      action.setAnswer({ ...data, rid: data.rid ? data.rid : response.data.rid, status: 1 })
    );
    yield put(action.getNewQuestionCount());
  } catch (error) {
    errorCallback();
  }
}

function* deleteAnswer({ payload }) {
  try {
    yield call(apiRequest, {
      url: "/talk/qna/doctor_answer/",
      method: "DELETE",
      data: payload,
    });

    yield put(action.setAnswer({ ...payload, rid: 0, status: 0, doctor_id: 0 }));
    yield put(action.getNewQuestionCount());
  } catch (error) {}
}

function* getNewQuestionCount() {
  try {
    const response = yield call(apiRequest, {
      url: "/talk/qna/new_question/count/",
      method: "GET",
    });

    yield put(action.setNewQuestionCount(response.data.new_question_count));
  } catch (error) {}
}

function* getMainConcerns() {
  try {
    const response = yield call(apiRequest, {
      url: "/talk/concern/main/categories/",
      method: "GET",
    });

    yield put(action.setMainConcerns(response.data));
  } catch (error) {}
}

function* getSubConcerns() {
  try {
    const response = yield call(apiRequest, {
      url: "/talk/concern/categories/",
      method: "GET",
    });

    yield put(action.setSubConcerns(response.data));
  } catch (error) {}
}

function* getConcerns() {
  yield all([getMainConcerns(), getSubConcerns()]);
}

export function* async() {
  yield takeLatest(action.getList, getList);

  yield takeLatest(action.checkAnswer, checkAnswer);
  yield takeLatest(action.putAnswer, putAnswer);
  yield takeLatest(action.deleteAnswer, deleteAnswer);

  yield takeLatest(action.getNewQuestionCount, getNewQuestionCount);

  yield takeLatest(action.getConcerns, getConcerns);
}
