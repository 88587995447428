import { Button, Typography, styled } from "@mui/material";
import useChallenge from "hooks/useChallenge";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Tab from "stories/v2/Tab";
import { CustomOpenInNewIcon } from "stories/v2/icons";
import Modal, { ButtonWrapper, ScrollContent } from "stories/v2/modal/Modal";

const Bold = styled("span")(({ theme }) => ({
  color: theme.palette.primary900.main,
}));

const Content = styled(ScrollContent)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 20,
  textAlign: "left",
  paddingBottom: "36px",
}));

const SubTitle = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 8,
}));

const Image = styled("div")(({ src }) => ({
  width: 536,
  height: 374,
  background: `url(${src}) no-repeat`,
  backgroundSize: "contain",
  transition: "0.2s",
}));

const Buttons = styled(ButtonWrapper)(() => ({
  width: "100%",
  ["& > *:first-child"]: {
    width: 140,
  },
  ["& > *:last-child"]: {
    width: "calc(100% - 140px - 8px)",
  },
}));

const RewardModal = ({ options, close }) => {
  const history = useHistory();
  const { rewards } = useChallenge();

  const [value, setValue] = useState(1);
  const tabItems = Object.keys(rewards)
    ?.filter(key => key >= 1)
    .map(key => ({ value: parseInt(key), label: rewards[key] }))
    .sort((a, b) => (b.value === 2 ? -1 : 1));

  return (
    <Modal
      title={
        <>
          <Bold>‘기능코 이벤트’</Bold> 등록 챌린지
        </>
      }
      width="600"
      close={close}
    >
      <Content>
        <Typography variant="body1">
          최근 고객이 관심이 높은 카테고리(최근 1개월동안 조회수가 높으면서 DB 유입되는 신청 건수가
          높은)의 등록 챌린지입니다.
        </Typography>

        <SubTitle>
          <Typography variant="h3">미션</Typography>
          <Typography variant="body1">
            ‘기능코' 카테고리를 선택하여 만들어주셔야 혜택 참여가 가능합니다.
            <br />
            이벤트 등록 완료 시,혜택을 선택하실 수 있습니다
            <br />
            (*해당 챌린지는 조기 종료될 수 있으며, 혜택 처리까지 다소 시간이 소요됩니다.).
          </Typography>
        </SubTitle>

        <SubTitle>
          <Typography variant="h3">챌린지 보상 (택1)</Typography>
          <Tab value={value} items={tabItems} onChange={setValue} />
          <Image src={require(`assets/images/challenge/reward${value}.png`)} />
          <Typography variant="body1">
            {value == 1
              ? "이벤트 페이지에 기능코 카테고리 내에 상위 영역에 독립적으로 보여지게 됩니다."
              : value === 3
              ? "기획전에 ‘기능코 특집’으로 노출되게 됩니다."
              : value === 2
              ? "5회의 상담 신청 건수를 무료로 제공합니다(충전금은 차감되나, 추후 ‘불량DB충전금'으로 환불됩니다). "
              : ""}
          </Typography>
        </SubTitle>
      </Content>

      {!options.hideButton && (
        <Buttons>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            startIcon={<CustomOpenInNewIcon color="grey500" />}
            onClick={() => {
              const openNewWindow = window.open("about:blank");
              openNewWindow.location.href =
                "https://www.notion.so/babitalk/147e23d1955e4d20ac9970de05c713af";
            }}
          >
            FAQ 확인
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              history.push("/event/edit?mid_category=3130");
            }}
          >
            등록하러 가기
          </Button>
        </Buttons>
      )}
    </Modal>
  );
};

export default RewardModal;
