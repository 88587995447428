import { createSlice } from "@reduxjs/toolkit";
import { listInitialState } from "./common/initialState";

const initialState = {
  money: 0, // 현재 미리결제 충전금
  budget: 0, // 예산
  expenses: 0, // 지출
  overview: {
    this_month_roas: 0,
    expacted_payback: 0,
    ex_month_payback: 0,
    ad_unit_price: 0,
    ad_event_cnt: 0,
    free_ad_event_cnt: 0,
    optimizing_ad_event_cnt: 0,
  },

  chargeList: {
    ...listInitialState,
  },
  unitPriceList: [],
  eventCountList: [],
  lastMonthSummary: {
    last_month_roas: 0,
    payback_money: 0,
    daily_money: [],
    all_payment_money: 0,
    all_spent_money: 0,
  },
};

export const name = "prepaymentCpm";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getMoney: () => {},
    chargeMoney: () => {},
    setMoney: (state, { payload }) => {
      state.money = payload;
    },

    getChargeList: () => {},
    setChargeList: (state, { payload }) => {
      const paginate = payload.paginate;
      if (paginate.page === 0) {
        state.chargeList.list = payload.list;
      } else if (paginate.page !== state.chargeList.paginate.page) {
        state.chargeList.list.push(...payload.list);
      }

      state.chargeList.paginate = paginate;
    },
    exportChargeList: () => {},

    getBudget: () => {},
    putBudget: () => {},
    setBudget: (state, { payload }) => {
      state.budget = payload;
    },

    getExpenses: () => {},
    setExpenses: (state, { payload }) => {
      state.expenses = payload;
    },

    getCpmSummary: () => {},
    setCpmSummary: (state, { payload }) => {
      state.unitPriceList = payload.cpm_dict;
      state.eventCountList = payload.event_dict;
    },

    getLastMonthSummary: () => {},
    setLastMonthSummary: (state, { payload }) => {
      state.lastMonthSummary = payload;
    },

    setOverview: (state, { payload }) => {
      state.overview = payload;
    },

    getCpmData: () => {},
    getChargeData: () => {},
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
