import styled, { css } from "styled-components";

export const Flex = styled.div`
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const FlexColumnStart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const FlexColumnStretch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
`;

export const FlexWrap = styled.div`
  display: flex;
  flex-direction: row nowrap;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
`;

export const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1px;

  & > * {
    flex: auto;
    min-width: 0;
  }
`;

export const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ gap }) => gap ?? 8}px;
`;

export const FlexBetween = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const flexCenter = css`
  display: flex;
  align-items: center;
`;

export const flexBaseLine = css`
  display: flex;
  align-items: baseline;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
