import { createSlice } from "@reduxjs/toolkit";

export const name = "hospital";
const slice = createSlice({
  name,
  initialState: {
    info: {},
    allCategories: [],
    surgeryCategories: [],
    treatmentsCategories: [],
  },
  reducers: {
    getHospitalInfo: () => {},
    postHospitalInfo: () => {},
    setHospitalInfo: (state, { payload }) => {
      state.info = payload;
      state.info.reset_youtube_channel_id = false;
      // 유튜브 url을 포함한 형태로 보낸다
      state.info.youtube_channel_id = !payload.youtube_channel_id
        ? null
        : (payload.youtube_channel_id && !payload.youtube_channel_id.includes("/channel/")
            ? "https://www.youtube.com/channel/"
            : "") + payload.youtube_channel_id;

      state.info.categories = [
        ...new Set([
          ...(payload.surgery_main_category_names || []),
          ...(payload.surgery_category_names || []),
          ...(payload.treatments_main_category_names || []),
          ...(payload.treatments_category_names || []),
        ]),
      ];
      state.info.main_categories = [
        ...(payload.surgery_main_category_names || []),
        ...(payload.treatments_main_category_names || []),
      ];

      state.info.work_hour_infos = {};
      payload.work_hour = payload.work_hour ? payload.work_hour : {};
      [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "lunch_break",
        "saturday",
        "sunday",
      ].map(name => {
        state.info.work_hour_infos[name] = {
          from: payload.work_hour[`${name}_from`],
          to: payload.work_hour[`${name}_to`],
          is_night: payload.work_hour[`${name}_is_night`],
          is_off: payload.work_hour[`${name}_is_off`],
        };
      });

      state.info.key = new Date().getTime();
    },
    resetYoutubeId: state => {
      state.info.reset_youtube_channel_id = true;
    },

    getBankInfo: () => {},
    postBankInfo: () => {},

    getCategories: () => {},
    setCategories: (state, { payload }) => {
      state.allCategories = payload;
      state.surgeryCategories = payload.filter(item => item.type === "성형");
      state.treatmentsCategories = payload.filter(item => item.type === "쁘띠/피부");
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
