import { put, call, takeLatest } from "redux-saga/effects";
import { apiRequest } from "../server_url";
import { action as mainAction } from "../modules/main";

function* getOverview() {
  try {
    const response = yield call(apiRequest, {
      url: "/home/overview/",
      method: "GET",
    });
    yield put(mainAction.setOverview(response.data));
  } catch (error) {}
}

function* getEventChart() {
  try {
    const response = yield call(apiRequest, {
      url: "/home/event/chart/",
      method: "GET",
    });
    let data = [];
    for (const [key, value] of Object.entries(response.data)) {
      const name = key === "0" ? "당월" : `${key} 달전`;
      data.push({ name: name, "신청 수": value });
    }
    yield put(mainAction.setEventChart(data.reverse()));
  } catch (error) {}
}

function* getOverviewPrepayment() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/cpc/overview/",
      method: "GET",
    });

    yield put(mainAction.setOverviewPrepayment(response.data));
  } catch (error) {}
}

function* getPrepaymentChart() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/chart/",
      method: "GET",
    });

    let data = [];
    for (const [key, value] of Object.entries(response.data)) {
      const name = key === "0" ? "당월" : `${key} 달전`;
      data.push({ name: name, "결제완료 수": value });
    }

    yield put(mainAction.setPrepaymentChart(data.reverse()));
  } catch (error) {}
}

function* getNoticeList() {
  try {
    const response = yield call(apiRequest, {
      url: "/home/notice/list/",
      method: "GET",
    });
    yield put(mainAction.setNoticeList(response.data));
  } catch (error) {}
}

function* getNoticePopup() {
  try {
    const response = yield call(apiRequest, {
      url: "/home/notice/popup/",
      method: "GET",
    });
    yield put(mainAction.setNoticePopup(response.data));
  } catch (error) {}
}

export function* mainAsync() {
  yield takeLatest(mainAction.getOverview, getOverview);
  yield takeLatest(mainAction.getEventChart, getEventChart);

  yield takeLatest(mainAction.getOverviewPrepayment, getOverviewPrepayment);
  yield takeLatest(mainAction.getPrepaymentChart, getPrepaymentChart);

  yield takeLatest(mainAction.getNoticeList, getNoticeList);
  yield takeLatest(mainAction.getNoticePopup, getNoticePopup);
}
