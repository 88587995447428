import moment from "moment";

export const today = moment().startOf("day");
export const tomorrow = moment().add(1, "d").startOf("day");
export const yesterday = moment().subtract(1, "d").startOf("day");

export const dateToString = (date = moment(), format = "YYYY-MM-DD") => {
  return moment(date).format(format);
};

export const datetimeToString = (date = moment(), format = "YYYY-MM-DD HH:mm:ss") => {
  return dateToString(date, format);
};

export const firstDayString = (date = moment()) => {
  return dateToString(moment(date).startOf("month"), "YYYY-MM") + "-01";
};

export const lastDayString = (date = moment()) => {
  return dateToString(moment(firstDayString(date)).add(1, "month").subtract(1, "day"));
};

export const addYear = (date = moment(), year = 1) => {
  return dateToString(moment(date).add(year, "year"));
};

export const subYear = (date = moment(), year = 1) => {
  return dateToString(moment(date).subtract(year, "year"));
};

export const addMonth = (date = moment(), month = 1) => {
  return dateToString(moment(date).add(month, "month"));
};

export const subMonth = (date = moment(), month = 1) => {
  return dateToString(moment(date).subtract(month, "month"));
};

export const addDay = (date = moment(), day = 6) => {
  return dateToString(moment(date).add(day, "day"));
};

export const subDay = (date = moment(), day = 6) => {
  return dateToString(moment(date).subtract(day, "day"));
};
