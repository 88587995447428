import "material-symbols/rounded.css";
import PropTypes from "prop-types";
import palette, { colorNames } from "../../theme/palette";

export const variantProps = ["outlined", "contained"];
const fontSizes = { initial: "initial", xsmall: 16, small: 20, medium: 24, large: 36 };
export const fontSizeProps = Object.keys(fontSizes);
export const colorProps = colorNames;

interface Props {
  name: string;
  variant?: string;
  fontSize?: any;
  color?: any;
  style?: any;
}

const Symbol = ({ name, variant, fontSize, color, style }: Props) => {
  return (
    <span
      className="material-symbols-rounded"
      style={{
        fontSize: fontSizes[fontSize],
        color: color ? palette[color].main : "inherit",
        fontVariationSettings: `'FILL' ${
          variant === "contained" ? 1 : 0
        }, 'wght' 400, 'GRAD' 0, 'opsz' 24`,
        ...style,
      }}
    >
      {name}
    </span>
  );
};

Symbol.propTypes = {
  name: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(variantProps),
  fontSize: PropTypes.oneOf(fontSizeProps),
  color: PropTypes.string,
};

Symbol.defaultProps = {
  name: "help",
  variant: "outlined",
  fontSize: "initial",
};

export default Symbol;
