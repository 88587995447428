import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { compare } from "compare-versions";
import ChromeDownload from "components/ChromeDownload";
import { detect } from "detect-browser";
import { retryLazy } from "helpers/retryLazy";
import useFontLoader from "hooks/common/useFontLoader";
import React, { Suspense } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

const App = () => {
  // 폰트 로더 사용 및 전역 스타일 적용
  const GlobalStyle = useFontLoader();
  // 크롬 브라우저 80 이상만 접속 가능
  const browser = detect();
  const isIncludesName = ["chrome", "crios"].includes(browser.name);
  if (!isIncludesName || (isIncludesName && compare(browser.version, "80", "<")))
    return <ChromeDownload />;

  const Login = retryLazy(() => import("components/pages/login/Login"));
  const MainIndex = retryLazy(() => import("components/pages/main/MainIndex"));
  const RankEventList = retryLazy(() => import("components/pages/rank/event/list"));
  const BoostList = retryLazy(() => import("components/pages/boost/list"));
  const BoostHistory = retryLazy(() => import("components/pages/boost/history"));
  const EventList = retryLazy(() => import("components/pages/event/eventList"));
  const EventResultsList = retryLazy(() => import("components/pages/event-results"));
  const EventPost = retryLazy(() => import("components/pages/event/EventPost"));
  const PrepaymentList = retryLazy(() => import("components/pages/prepayment/PrepaymentList"));
  const PrepaymentDetail = retryLazy(() => import("components/pages/prepayment/PrepaymentDetail"));
  const PrepaymentOptions = retryLazy(
    () => import("components/pages/prepayment/PrepaymentOptions")
  );
  const PrepaymentTreatments = retryLazy(
    () => import("components/pages/prepayment/PrepaymentTreatments")
  );
  const PaymentList = retryLazy(() => import("components/pages/prepayment_payment/PaymentList"));
  const SettlementList = retryLazy(
    () => import("components/pages/prepayment_payment/SettlementList")
  );
  const QnaList = retryLazy(() => import("components/pages/prepayment_qna/QnaList"));
  const Ask = retryLazy(() => import("components/pages/ask"));
  const PreAsk = retryLazy(() => import("components/pages/pre_ask"));
  const PreAskEvent = retryLazy(() => import("components/pages/pre_ask_event"));
  const Hospital = retryLazy(() => import("components/pages/hospital/Hospital"));
  const Telephone = retryLazy(() => import("components/pages/telephone/Telephone"));
  const PayProfile = retryLazy(() => import("components/pages/pay/PayProfile"));
  const ChargeList = retryLazy(() => import("components/pages/pay/chargeList"));
  const DoctorList = retryLazy(() => import("components/pages/doctor/list/DoctorList"));
  const DoctorPost = retryLazy(() => import("components/pages/doctor/post/DoctorPost"));
  const AccountManage = retryLazy(() => import("components/pages/account/AccountManage"));
  const ImagePage = retryLazy(() => import("ImagePage"));
  const DoctorAsk = retryLazy(() => import("components/pages/doctor_ask/DoctorAsk"));
  const advertisePurchase = retryLazy(() => import("components/pages/advertise/Purchase"));
  const advertiseList = retryLazy(() => import("components/pages/advertise/List"));
  const advertisePost = retryLazy(() => import("components/pages/advertise/Post"));
  const TalkQnaList = retryLazy(() => import("components/pages/talk_qna/TalkQnaList"));
  const Treatment = retryLazy(() => import("components/pages/hospitalTreatment/list"));

  const OnsitePaymentList = retryLazy(() => import("components/pages/onsitePayment/list"));

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <Router>
        <Suspense fallback={<></>}>
          {/* rank */}
          <Route path="/rank/event" exact component={RankEventList} />

          {/* 병원 시술 */}
          <Route path="/treatment" exact component={Treatment} />

          {/* event */}
          <Route path="/event" exact component={EventList} />
          <Route path="/event/edit/:id" exact component={EventPost} />
          <Route path="/event/edit" exact component={EventPost} />

          {/* event-results */}
          <Route path="/event_results" exact component={EventResultsList} />

          {/* prepayment */}
          <Route path="/prepayment" exact component={PrepaymentList} />
          <Route path="/prepayment/edit/:id" exact component={PrepaymentDetail} />
          <Route path="/prepayment/edit" exact component={PrepaymentDetail} />
          <Route path="/prepayment/options/edit/:id" exact component={PrepaymentOptions} />
          <Route path="/prepayment/options/edit" exact component={PrepaymentOptions} />
          <Route path="/prepayment/treatments" exact component={PrepaymentTreatments} />

          {/* prepayment payment */}
          <Route path="/prepayment/payment/list" exact component={PaymentList} />
          <Route path="/prepayment/payment/settlement/list" exact component={SettlementList} />

          {/* prepayment qna */}
          <Route path="/prepayment/qna" exact component={QnaList} />

          {/* talk qna */}
          <Route path="/talk/qna" exact component={TalkQnaList} />

          {/* doctor ask */}
          <Route path="/ask/doctor" exact component={DoctorAsk} />

          {/* ask */}
          <Route path="/ask" exact component={Ask} />
          <Route path="/pre_ask" exact component={PreAsk} />
          <Route path="/pre_ask_event" exact component={PreAskEvent} />

          {/* boost */}
          <Route path="/boost/list" exact component={BoostList} />
          <Route path="/boost/history" exact component={BoostHistory} />

          {/* push_popup */}
          <Route path="/advertise/purchase/:type?" component={advertisePurchase} />
          <Route path="/advertise" exact component={advertiseList} />
          <Route path="/advertise/post/:id?" exact component={advertisePost} />

          {/* hospital */}
          <Route path="/hospital" exact component={Hospital} />

          {/* doctor */}
          <Route path="/doctor" exact component={DoctorList} />
          <Route path="/doctor/edit" exact component={DoctorPost} />
          <Route path="/doctor/edit/:id" exact component={DoctorPost} />

          {/* telephone */}
          <Route path="/telephone" exact component={Telephone} />

          {/* pay */}
          <Route path="/pay/profile" exact component={PayProfile} />
          <Route path="/pay/list" exact component={ChargeList} />

          {/* 현장결제 */}
          <Route path="/onsite_payment/list" exact component={OnsitePaymentList} />

          {/* etc */}
          <Route path="/login" component={Login} />
          <Route path="/account" component={AccountManage} />
          <Route path="/image" exact component={ImagePage} />
          <Route path="/" exact component={MainIndex} />
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
