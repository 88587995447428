import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import { palette, typography } from "theme";

export const sizeProps = ["small", "medium"];

const sizes = {
  small: {
    fontSize: "13px",
    fontWeight: 600,
  },
  medium: {
    fontSize: "16px",
    fontWeight: 500,
  },
};

const customTheme = ({ size }) => {
  return createTheme({
    palette,
    typography,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            width: "100%",
            borderBottom: `1px solid ${palette?.grey300.main}`,
            minHeight: "auto",
            background: "#fff",
            "& .MuiTabs-flexContainer": {
              gap: "16px",
            },
            "&&& .MuiTabs-indicator": {
              backgroundColor: palette?.primary700.main,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            ...sizes[size],
            textTransform: "none",
            minWidth: "34px",
            minHeight: "34px",
            color: palette?.grey700.main,
            lineHeight: "100%",
            transition: "0.2s",
            padding: 0,
            paddingBottom: "8px",
            "&.Mui-selected": {
              color: palette?.grey900.main,
            },
          },
        },
      },
    },
  });
};

const Tab = ({ items = [], value = 0, onChange, size = "small", ...props }) => {
  const handleChange = (e, value) => {
    onChange(value);
  };

  return (
    <ThemeProvider theme={customTheme({ size })}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        {...props}
      >
        {items?.map((v, i) => (
          // v: value, label
          <MuiTab disableripple="true" key={i} value={v.value} label={v.label} />
        ))}
      </Tabs>
    </ThemeProvider>
  );
};

Tab.propTypes = {
  items: PropTypes.array,
  value: PropTypes.number,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(sizeProps),
};

export default Tab;
