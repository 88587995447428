import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tokenExpire: null,
  phone: null,
};

export const name = "account";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getTokenExpire: () => {},
    changeTokenExpire: () => {},
    setTokenExpire: (state, action) => {
      state.tokenExpire = action.payload;
    },
    changePassword: () => {},
    // 계정정보 > 2단계 인증(연락처)
    getLoginPhone: () => {},
    postLoginPhone: () => {},
    deleteLoginPhone: () => {},
    setLoginPhone: (state, action) => {
      state.phone = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
