import { dataURLtoFile } from "helpers/base64_to_file";
import { action as eventAction } from "modules/event";
import { serialize } from "object-to-formdata";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { apiRequest } from "server_url";

function* getEvents() {
  try {
    const response = yield call(apiRequest, {
      url: "/event/list/",
      method: "GET",
    });

    yield put(eventAction.setEvents(response.data));
  } catch (error) {}
}

function* getEvent({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/event/detail/",
      method: "GET",
      params: {
        bid: payload,
      },
    });

    yield put(eventAction.setEvent(response.data));
  } catch (error) {}
}

function* stopEvent({ payload }) {
  try {
    const { bid, csmemo } = payload;
    yield call(apiRequest, {
      url: "/event/stop/",
      method: "POST",
      data: {
        bid,
        csmemo,
      },
    });

    yield put(eventAction.stopEventSuccess({ bid, csmemo }));
  } catch (error) {}
}

function* postEvent({ payload }) {
  const { banner, event, sub_categories } = payload.data;
  const { callback, onError } = payload;

  const data = {
    ...banner,
    ...event,
    timg: banner.timg?.url ? null : banner.timg,
    img: event.img?.url ? null : event.img,
    sub_categories: sub_categories?.map(item => ({ category_id: item })),
  };

  delete data.subCategories;
  delete data.category_type;

  try {
    const response = yield call(apiRequest, {
      url: data?.id ? "/event/inspect/" : "/event/detail/",
      method: "POST",
      data: serialize({
        json_data: JSON.stringify(data),
        img: event.img?.url ? dataURLtoFile(event.img.url, "img") : null,
        timg: banner.timg?.url ? dataURLtoFile(banner.timg.url, "timg") : null,
      }),
    });

    callback && callback(response?.data);
  } catch (error) {
    onError && onError(error);
  }
}

function* inspectDeleteEvent({ payload }) {
  try {
    yield call(apiRequest, {
      url: "/event/inspect/",
      method: "DELETE",
      data: payload.data, // { banner_id, inspect_temp_id}
    });

    yield put(eventAction.inspectDeleteEventSuccess(payload.data));
    payload.onSuccess && payload.onSuccess();
  } catch (error) {}
}

function* deleteEvent({ payload }) {
  const bid = payload?.data || payload;
  try {
    yield call(apiRequest, {
      url: "/event/detail/",
      method: "DELETE",
      data: {
        bid,
      },
    });

    alert("삭제 되었습니다.");
    payload.onSuccess && payload.onSuccess();
    yield put(eventAction.deleteEventSuccess(bid));
  } catch (error) {}
}

function* toggleAdScopeType({ payload }) {
  const { data, onSuccess, onError } = payload;
  try {
    yield call(apiRequest, {
      url: "/event/ad_scope_type",
      method: "PUT",
      data,
    });

    yield put(eventAction.toggleAdScopeTypeSuccess(payload));
    onSuccess();
  } catch (error) {
    onError();
  }
}

function* toggleExternalAd({ payload }) {
  const { data, onSuccess, onError } = payload;
  try {
    yield call(apiRequest, {
      url: "/event/external_ad/",
      method: "PUT",
      data,
    });

    yield put(eventAction.toggleExternalAdSuccess(payload));
    onSuccess();
  } catch (error) {
    onError();
  }
}

function* toggleGooddocExternalAd({ payload }) {
  const { data, onSuccess, onError } = payload;
  try {
    yield call(apiRequest, {
      url: "/event/gooddoc_external_ad",
      method: "PUT",
      data,
    });

    yield put(eventAction.toggleGooddocExternalAdSuccess(payload));
    onSuccess();
  } catch (error) {
    onError();
  }
}

function* getSurgeryCategories(action) {
  try {
    const response = yield call(apiRequest, {
      url: "/event/v2/surgery/categories/",
      method: "GET",
    });

    yield put(
      eventAction.setCategories({
        type: "surgeryCategories",
        data: response.data.surgery_categories,
      })
    );
    action?.payload && action.payload();
  } catch (error) {}
}

function* getTreatmentsCategories(action) {
  try {
    const response = yield call(apiRequest, {
      url: "/event/v2/treatments/categories/",
      method: "GET",
    });

    yield put(
      eventAction.setCategories({
        type: "treatmentsCategories",
        data: response.data.treatments_categories,
      })
    );
    action?.payload && action.payload();
  } catch (error) {}
}

function* getAllCategories() {
  yield all([getSurgeryCategories(), getTreatmentsCategories()]);
}

function* getEventNCategories({ payload }) {
  yield all([getSurgeryCategories(), getTreatmentsCategories()]);
  yield put(eventAction.getEvent(payload));
}

export function* async() {
  yield takeLatest(eventAction.getEvents, getEvents);
  yield takeLatest(eventAction.getEvent, getEvent);
  yield takeLatest(eventAction.getEventNCategories, getEventNCategories);
  yield takeLatest(eventAction.stopEvent, stopEvent);
  yield takeLatest(eventAction.postEvent, postEvent);
  yield takeLatest(eventAction.deleteEvent, deleteEvent);
  yield takeLatest(eventAction.inspectDeleteEvent, inspectDeleteEvent);
  yield takeLatest(eventAction.toggleAdScopeType, toggleAdScopeType);
  yield takeLatest(eventAction.toggleExternalAd, toggleExternalAd);
  yield takeLatest(eventAction.toggleGooddocExternalAd, toggleGooddocExternalAd);

  yield takeLatest(eventAction.getSurgeryCategories, getSurgeryCategories);
  yield takeLatest(eventAction.getTreatmentsCategories, getTreatmentsCategories);
  yield takeLatest(eventAction.getAllCategories, getAllCategories);
}
