import React from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import ThemeProvider from "ThemeProvider";
import ChromeIcon from "assets/images/Google_Chrome_icon2x.png";

const Content = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  & > * {
    max-width: 480px;
    text-align: center;
  }
`;

const Ttitle = styled.div`
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: #484760;
`;

const Image = styled.div`
  width: 88px;
  height: 88px;
  background: url(${ChromeIcon});
  background-size: contain;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #484760;
  margin-bottom: 8px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #484760;

  color: #a3a3af;
  margin-top: 22px;
`;

const ChromeDownload = () => (
  <ThemeProvider>
    <Content>
      <Ttitle>권장 브라우저 안내</Ttitle>
      <Image />
      <Text>
        바비톡 병원관리자는 크롬 브라우저에 최적화되어있어,
        <br />
        보다 안전한 사용을 위해 크롬 브라우저를 권장합니다.
        <br />
        아래의 버튼을 눌러 크롬 다운로드 및 설치를 완료하신 후
        <br />
        다시 시도해 주세요.
        <Description>크롬 이외 다른 브라우저 사용 시 오류가 발생할 수 있습니다.</Description>
      </Text>
      <Button
        variant="contained"
        color="secondary"
        size="xxlarge"
        sx={{ width: "520px" }}
        onClick={() =>
          window.open("https://www.google.co.kr/intl/ko/chrome/", "_blank", "noopener,noreferrer")
        }
      >
        크롬 다운로드하기
      </Button>
    </Content>
  </ThemeProvider>
);

export default ChromeDownload;
