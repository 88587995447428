import React from "react";
import styled, { css } from "styled-components";
import { flexCenter, flexColumn } from "components/common/Flex";
import { backgroundIcon } from "components/common/css";
import RightArrowIcon from "assets/images/eventCategory/ic_arrow_right_xs.svg";

const CategoryContent = styled.div`
  ${flexColumn}
  gap: 8px;

  width: 220px;
`;

const CategoryTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7e7e8f;
`;

const CategoryBox = styled.div`
  width: 100%;
  height: 200px;
  max-height: 200px;

  overflow-y: auto;

  border: 1px solid #ececef;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 8px 0px;

  ${({ disabled }) =>
    disabled &&
    css`
      background: #ececef;
    `}
`;

const rightArrow = css`
  &:after {
    content: "";
    position: absolute;
    top: 6px;
    right: 8px;

    ${backgroundIcon(RightArrowIcon)}
  }
`;

const CategoryItem = styled.div`
  position: relative;

  padding: 6px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #484760;
  word-break: break-all;

  ${({ on, lastItem }) =>
    on
      ? css`
          font-weight: 700;
          color: #897dff;
          background: #f7f7f9;

          ${!lastItem && rightArrow}
        `
      : css`
          &:hover {
            background: #f4f3ff;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #897dff;
          }

          ${lastItem
            ? css`
                &:hover {
                  padding-right: 58px;

                  &:after {
                    content: "+ 추가";
                    position: absolute;
                    top: 3px;
                    right: 8px;

                    background: #604aff;
                    border-radius: 4px;
                    padding: 4px 8px;

                    font-weight: 700;
                    font-size: 10px;
                    line-height: 12px;
                    color: #ffffff;
                  }
                }
              `
            : rightArrow}
        `};
`;

const EmptyCategoryItem = styled.div`
  width: 100%;
  height: 100%;
  ${flexCenter}
  justify-content: center;
`;

const CategoriesBox = ({ title, list, disabled, value, onChange, placeholder }) => (
  <CategoryContent>
    <CategoryTitle>{title}</CategoryTitle>
    <CategoryBox disabled={disabled}>
      {list?.length ? (
        list?.map(item => {
          const lastItem = !item?.subcategories?.length;

          return (
            <CategoryItem
              key={item.id}
              on={!lastItem && item.id === value?.id ? 1 : 0}
              onClick={() => !disabled && onChange(item)}
              lastItem={lastItem}
            >
              {item.name}
            </CategoryItem>
          );
        })
      ) : (
        <EmptyCategoryItem>{placeholder || "세부분류가 없습니다"}</EmptyCategoryItem>
      )}
    </CategoryBox>
  </CategoryContent>
);

export default CategoriesBox;
