import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import Link from "@mui/material/Link";
import { Fragment } from "react";
import styled from "styled-components";
import { CustomHomeIcon } from "../Icons";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 2px;

  font-size: 13px;
  font-weight: 500;

  &:last-child {
    color: ${({ theme }) => theme.palette.grey600.main};
  }
`;

const CustomLink = ({ children, ...props }) => (
  <StyledLink color="grey500.main" underline="none" {...props}>
    {children}
  </StyledLink>
);

const Breadcrumbs = ({ crumbs, mainUrl = "/" }) => {
  return (
    <Wrapper separator={<ArrowRightRoundedIcon color="grey500" fontSize="xsmall" />}>
      <CustomLink href={mainUrl}>
        <CustomHomeIcon color="grey500" fontSize="xsmall" />
        바비톡
      </CustomLink>

      {crumbs?.map((item, index) => (
        <Fragment key={index}>
          <ArrowRightRoundedIcon key={`${index}_1`} color="grey500" fontSize="xsmall" />
          <CustomLink key={`${index}_2`} href={item.href}>
            {item.text}
          </CustomLink>
        </Fragment>
      ))}
    </Wrapper>
  );
};

export default Breadcrumbs;
