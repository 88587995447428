export function isObject(object) {
  return object != null && typeof object === "object";
}

export function isEmptyObject(object) {
  return !(isObject(object) && Object.values(object).length);
}

export default function is_equal_object(object1, object2) {
  const keys1 = (object1 && Object.keys(object1)) || [];
  const keys2 = (object2 && Object.keys(object2)) || [];
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !is_equal_object(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}

export const arrayToObject = (array, key) => {
  if (!array?.length) return {};
  return array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    {}
  );
};
