import { call, put, takeLatest } from "redux-saga/effects";
import { action as categoryAction } from "../modules/category";
import { apiRequest } from "../server_url";

function* getLocations() {
  try {
    const response = yield call(apiRequest, {
      url: "/hospital/v2/location/categories/",
      method: "GET",
      errorAlert: false,
    });

    yield put(categoryAction.setLocations(response.data));
  } catch (error) {}
}

export function* async() {
  yield takeLatest(categoryAction.getLocations, getLocations);
}
