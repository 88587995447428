import React from "react";
import styled from "styled-components";
import Modal from "components/modals/base/Modal";
import { FlexEnd } from "components/common/Flex";
import { Button } from "@mui/material";
import useModal from "hooks/common/useModal";

const ModalContent = styled.div`
  padding: 20px;
  button {
    width: 100%;
  }
`;

const Body = styled.div`
  padding-bottom: 24px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #484760;
  text-align: center;

  & div + div {
    margin-top: 16px;
  }
`;

export const AnswerBlind = ({ isBlind, blind_type, blind_reason, blindReasons }) => {
  const modal = useModal();
  const openBlindReasonModal = () => {
    let reason = blind_reason;
    if (blind_type !== 99) {
      const find = blindReasons.find(item => item.type === blind_type);
      reason = find.reason;
    }
    modal.blindReason(reason);
  };

  return isBlind ? (
    <Button color="danger" variant="outlined" size="xsmall" onClick={openBlindReasonModal}>
      블라인드 중
    </Button>
  ) : (
    "답변 노출 중"
  );
};

const BlindReason = ({ options, close }) => {
  const { reason } = options;

  return (
    <Modal width={352} close={close} isBlockBackdropClick>
      <ModalContent>
        <Body>
          <div>
            <b>블라인드 사유:</b>
            <br /> {reason}
          </div>
          <div>
            사유에 맞춰 내용을 수정하면 운영팀에서
            <br />
            내용 검토 후 블라인드를 해제합니다.
            <br />
            더 자세한 사항은 고객센터로
            <br />
            (cs@babitalk.com) 문의주세요.
          </div>
        </Body>
        <FlexEnd>
          <Button variant="contained" color="secondary" onClick={() => close("confirm")}>
            확인
          </Button>
        </FlexEnd>
      </ModalContent>
    </Modal>
  );
};

export default BlindReason;
