import ArrowDropDownRounded from "@mui/icons-material/ArrowDropDownRounded";
import { Typography } from "@mui/material";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import InputBase from "../Input/Base";
import MenuItem from "../MenuItem";

const SelectBox = ({
  options,
  placeholder,
  disabled,
  optionValue = "value",
  optionLabel = "name",
  onChange,
  defaultValue,
  ...props
}) => {
  return (
    <Select
      defaultValue={defaultValue ?? (props.multiple ? [] : {})}
      input={<InputBase />}
      IconComponent={ArrowDropDownRounded}
      displayEmpty={Boolean(placeholder)}
      renderValue={selected => {
        if (typeof selected === "object" && selected?.length) {
          const match = options
            ?.map(item =>
              selected.find(select => select === item[optionValue]) ? item[optionLabel] : ""
            )
            ?.filter(item => item)
            ?.map((item, index) => (
              <>
                {index > 0 ? ", " : ""}
                {item}
              </>
            ));
          if (match) return match;
        } else if (selected) {
          const match = options?.find(item => item[optionValue] === selected)?.[optionLabel];
          if (match) return match;
        }

        return (
          <Typography variant="body1" color="grey600.main" sx={{ lineHeight: "16px" }}>
            {placeholder}
          </Typography>
        );
      }}
      disabled={Boolean(disabled || !options?.length)}
      MenuProps={{
        MenuListProps: { disablePadding: true },
        PaperProps: {
          style: {
            maxHeight: 400,
          },
        },
      }}
      onChange={selected => {
        onChange && onChange(selected.target.value);
      }}
      {...props}
    >
      {options?.map(item => (
        <MenuItem key={item[optionValue]} value={item[optionValue]} {...item}>
          {item[optionLabel]}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectBox.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  optionValue: PropTypes.string,
  optionLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SelectBox;
