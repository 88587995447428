import { createSlice } from "@reduxjs/toolkit";

export const name = "sidebar";
const slice = createSlice({
  name,
  initialState: {
    pushPopupCount: 0,
    uncheckedCount: {
      push_popup: 0,
      motion: 0,
      wide: 0,
    },
    prepaymentQnaCount: 0,
    talkQnaCount: 0,
    newReservationCount: 0,

    // 이벤트 랭킹관리
    eventRankCountDetail: {
      ranking_increase_event_count: 0, // 이전 대비 상승 이벤트 수
      ranking_decrease_event_count: 0, // 이전 대비 하락 이벤트 수
      ranking_keep_event_count: 0, // 이전 대비 유지 이벤트 수
    },

    // 앱 예약 노출 (beta)
    reservable: false,
    appReservation: false,
  },
  reducers: {
    getMenu: () => {},
    setMenu: (state, action) => {
      const {
        prepayment_event_qna_count,
        talk_qna_count,
        reservable,
        new_reservation_count,
        app_reservation,
        app_reservation_count,
      } = action.payload;
      state.prepaymentQnaCount = prepayment_event_qna_count;
      state.talkQnaCount = talk_qna_count;
      state.newReservationCount = new_reservation_count;
      state.reservable = reservable;
      state.appReservation = app_reservation;
      state.appReservationCount = app_reservation_count;
    },

    getPushPopupCount: () => {},
    setPushPopupCount: (state, action) => {
      const { push_popup, motion, wide } = action.payload;
      state.pushPopupCount = push_popup + motion + wide;
      state.uncheckedCount = action.payload;
    },

    getOverviewEventRank: () => {},
    setOverviewEventRank: (state, action) => {
      state.eventRankCountDetail = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
