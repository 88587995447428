export default {
  MuiButton: {
    styleOverrides: {
      root: {
        display: "flex",
        gap: "6px",
        minWidth: "fit-content",
        boxShadow: "none",
        borderRadius: "8px",
        fontWeight: 700,
        lineHeight: 1,
        "& .MuiButton-startIcon": {
          marginRight: 0,
          marginLeft: 0,
        },
        "& .MuiButton-endIcon": {
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },
    variants: [
      {
        props: { color: "secondary" },
        style: ({ theme }) => ({
          borderColor: theme.palette.grey300.main,
          "&:focus": {
            borderColor: theme.palette.grey300.main,
          },
          "&:hover": {
            borderColor: theme.palette.grey300.main,
          },
        }),
      },
      {
        props: { variant: "contained", disabled: false },
        style: ({ theme, ownerState }) => {
          return {
            border: `1px solid ${theme?.palette[ownerState.color]?.main}`,
          };
        },
      },
      {
        props: { variant: "contained", disabled: true },
        style: ({ theme }) => {
          return {
            background: `${theme?.palette.grey200.main} !important`,
            border: `1px solid ${theme?.palette.grey300.main}`,
          };
        },
      },
      {
        props: { disabled: true },
        style: ({ theme }) => {
          return {
            border: `1px solid ${theme?.palette.grey300.main}`,
          };
        },
      },
      {
        props: { variant: "text" },
        style: () => {
          return {
            border: "none",
          };
        },
      },
      {
        props: { size: "xsmall" },
        style: {
          padding: "3px 5px",
          borderRadius: "4px",
          fontSize: "12px",
          lineHeight: "16px",
          gap: "2px",
          "& .MuiButton-startIcon": {
            svg: {
              fontSize: 16,
            },
          },
          "& .MuiButton-endIcon": {
            svg: {
              fontSize: 16,
            },
          },
        },
      },
      {
        props: { size: "small" },
        style: {
          padding: "9px",
          borderRadius: "6px",
          fontSize: "12px",
          lineHeight: "16px",
          gap: "4px",
          "& .MuiButton-startIcon": {
            svg: {
              fontSize: 16,
            },
          },
          "& .MuiButton-endIcon": {
            svg: {
              fontSize: 16,
            },
          },
        },
      },
      {
        props: { size: "medium" },
        style: {
          padding: "8px 13px",
          fontSize: "14px",
          lineHeight: "20px",
          "& .MuiButton-startIcon": {
            svg: {
              fontSize: 20,
            },
          },
          "& .MuiButton-endIcon": {
            svg: {
              fontSize: 20,
            },
          },
        },
      },
      {
        props: { size: "large" },
        style: {
          padding: "11px 15px",
          fontSize: "16px",
          lineHeight: "24px",
          "& .MuiButton-startIcon": {
            svg: {
              fontSize: 24,
            },
          },
          "& .MuiButton-endIcon": {
            svg: {
              fontSize: 24,
            },
          },
        },
      },
      {
        props: { size: "xxlarge" },
        style: {
          padding: "24px",
          fontSize: "24px",
          lineHeight: "29px",
          "& .MuiButton-startIcon": {
            svg: {
              fontSize: 24,
            },
          },
          "& .MuiButton-endIcon": {
            svg: {
              fontSize: 24,
            },
          },
        },
      },
      // 그라데이션 버튼 추가
      {
        props: { variant: "gradation" },
        style: ({ theme, ownerState }) => {
          const { color } = ownerState;
          return {
            border: "solid 1px transparent",
            background: `linear-gradient(90deg, ${theme.palette[color].light} 0%, ${theme.palette[color].main} 50%, ${theme.palette[color].dark} 100%)`,
            backgroundOrigin: "border-box",
            color: theme.palette[color].contrastText,
            "&:hover": {
              background: `linear-gradient(0deg, rgba(28, 27, 50, 0.12) 0%, rgba(28, 27, 50, 0.12) 100%), linear-gradient(90deg, ${theme.palette[color].light} 0%, ${theme.palette[color].main} 50%, ${theme.palette[color].dark} 100%)`,
              backgroundOrigin: "border-box",
            },
          };
        },
      },
      // white 버튼 추가
      {
        props: { color: "white", variant: "contained" },
        style: ({ theme }) => {
          return {
            border: `solid 1px ${theme.palette.grey300.main}`,
          };
        },
      },
      {
        props: { color: "white", variant: "outlined" },
        style: ({ theme }) => {
          return {
            border: `solid 1px ${theme.palette.white.main}`,
          };
        },
      },
    ],
  },
};
