import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;

  h6 {
    min-width: 132px;
    max-width: 132px;
    box-sizing: content-box;
    padding: 9px 0px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  flex: 1 1 0;
  max-width: 600px;

  p {
    padding: 2.5px 0px 2.5px 2px;
    word-break: break-all;
  }
`;
