function check(value, alertMsg = "", type = "") {
  const result = true;

  switch (typeof value) {
    case "string":
      if (!value || !value.trim()) {
        if (alertMsg.trim()) alert(alertMsg);
        return false;
      }

      if (type) {
        let reg;
        let regName = "";

        if (type === "email") {
          reg = /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
          regName = "이메일";
        } else if (type === "phone") {
          reg = /^0[1,7]+0[-]?\d{3,4}[-]?\d{4}$/;
          regName = "전화번호";
        } else if (type === "telephone") {
          reg = /^\d{2,3}[-]?\d{3,4}[-]?\d{4}$/;
          regName = "전화번호";
        } else if (type === "corpNo") {
          reg = /^\d{3}[-]?\d{2}[-]?\d{5}$/;
          regName = "사업자등록번호";
        }

        if (!reg.test(value)) {
          alert("올바르지 않은" + (regName ? " " : "") + regName + " 형식입니다.");
          return false;
        }
      }

      break;

    case "number":
      if (!value || value <= 0) {
        if (alertMsg.trim()) alert(alertMsg);
        return false;
      }
      break;

    case "object":
      if (value instanceof Date) return result;
      if (!value || !Object.keys(value).length) {
        if (alertMsg.trim()) alert(alertMsg);
        return false;
      }
      break;

    default:
      if (!value) {
        if (alertMsg.trim()) alert(alertMsg);
        return false;
      }
      break;
  }

  return result;
}

/**
 * 빈 값 체크 (null, undefined, '' 등)
 * @param {any} param [{ value:..., alerMsg: ..., type: ... }, ...] OR { value:..., alerMsg: ..., type: ... } OR [value, value ...] OR value
 * @param {any} param.value 검사가 필요한 값
 * @param {stirng} param.alerMsg 빈값인경우 보여줄 alert 문구
 * @param {stirng} param.type 정규식으로 규칙 확인 : phone, email, telephone, corpNum
 * @return {boolean} false: empty
 */
export default function isSet(param) {
  try {
    if (Array.isArray(param) && param.length > 0) {
      for (let i = 0; i < param.length; i++) {
        if (!isSet(param[i])) return false;
      }
    } else if (typeof param === "object" && !!Object.keys(param).find(key => key === "value")) {
      const { value, alertMsg = "", type = "" } = param;
      return check(value, alertMsg, type);
    } else {
      return check(param);
    }
  } catch (e) {
    return check(param);
  }

  return true;
}
