import { put, call, takeLatest } from "redux-saga/effects";
import { action as doctorAskAction } from "modules/doctorAsk";
import { apiRequest } from "server_url";

function* getDoctorAsk() {
  try {
    const response = yield call(apiRequest, {
      url: "/doctor/client/live/list/",
      method: "GET",
    });
    yield put(doctorAskAction.setDoctorAsk(response.data));
  } catch (err) {}
}

function* getOverallDoctorCounsel() {
  try {
    const response = yield call(apiRequest, {
      url: "/doctor/event/",
      method: "GET",
    });
    yield put(doctorAskAction.setOverallDoctorCounsel(response.data.is_on));
  } catch (err) {}
}

function* toggleOverallDoctorCounsel({ payload }) {
  const is_on = payload;
  try {
    yield call(apiRequest, {
      url: "/doctor/event/",
      method: !is_on ? "POST" : "DELETE",
    });
    yield put(doctorAskAction.setOverallDoctorCounsel(!is_on));
    yield put(doctorAskAction.getDoctorAsk());
    if (!is_on) alert("의사 상담 신청이 활성화 되었습니다.");
    else alert("의사 상담 신청이 비활성화 되었습니다.");
  } catch (err) {}
}

function* toggleDoctorCounsel({ payload }) {
  const { id, is_on } = payload;
  try {
    yield call(apiRequest, {
      url: "/doctor/event/",
      method: is_on ? "POST" : "DELETE",
      data: { doctor_id: id },
    });
    yield put(doctorAskAction.getDoctorAsk());
  } catch (err) {}
}

function* changeDoctorAskOrder(action) {
  const doctors = action.payload;
  const orderDoctors = doctors.reduce((map, obj, index) => {
    map[obj.id] = index + 1;
    return map;
  }, {});

  try {
    yield call(apiRequest, {
      url: "/doctor/event/sort/priority/",
      method: "POST",
      data: { doctor_priority: orderDoctors },
    });
    yield put(doctorAskAction.getDoctorAsk());
  } catch (err) {}
}

export function* doctorAskAsync() {
  yield takeLatest(doctorAskAction.getDoctorAsk, getDoctorAsk);

  yield takeLatest(doctorAskAction.getOverallDoctorCounsel, getOverallDoctorCounsel);
  yield takeLatest(doctorAskAction.toggleOverallDoctorCounsel, toggleOverallDoctorCounsel);
  yield takeLatest(doctorAskAction.toggleDoctorCounsel, toggleDoctorCounsel);

  yield takeLatest(doctorAskAction.changeDoctorAskOrder, changeDoctorAskOrder);
}
