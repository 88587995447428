import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  img {
    width: 100%;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.palette.grey900.main};
  font-size: 18px;
  font-weight: 600;
  line-height: 130%; /* 23.4px */

  b {
    color: ${({ theme }) => theme.palette.primary900.main};
    font-weight: 600;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  & > button {
    flex: 1;
  }

  ${({ direction }) =>
    direction === "vertical" &&
    css`
      flex-direction: column;
    `}
`;
