import { createSlice } from "@reduxjs/toolkit";
import { listInitialState } from "./common/initialState";

const initialState = {
  ...listInitialState,
  blindReasons: [
    { type: 1, reason: "상업성/광고성 게시물 작성" },
    { type: 2, reason: "개인정보 노출 (연락처, SNS, 카톡 id 등)" },
    { type: 3, reason: "욕설/비방/도배글 작성" },
    { type: 4, reason: "견적 관련 답변 (이벤트가) 작성" },
  ],
  newQuestionCount: 0,

  // 고민부위(카테고리)
  mainConcerns: [],
  subConcerns: [],
};

export const name = "talk_qna";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getList: () => {},
    setList: (state, { payload }) => {
      state.list = payload.qna_list;
      state.paginate = payload.paginate;
    },

    checkAnswer: () => {},
    putAnswer: () => {},
    deleteAnswer: () => {},
    setAnswer: (state, { payload }) => {
      const { id, rid, answer, status, doctor_id } = payload;

      const find = state.list?.find(item => item.id === id);
      if (find) {
        find.answer = answer;
        find.status = status;
        find.rid = rid;
        find.doctor_id = doctor_id;
      }
    },

    getNewQuestionCount: () => {},
    setNewQuestionCount: (stage, { payload }) => {
      stage.newQuestionCount = payload;
    },

    getConcerns: () => {},
    setMainConcerns: (state, { payload }) => {
      state.mainConcerns = payload;
    },
    setSubConcerns: (state, { payload }) => {
      state.subConcerns = payload;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
