import { Button, Typography } from "@mui/material";
import React from "react";
import { Symbol } from "stories/v2/Symbol";
import Modal from "./Modal";
import { Buttons, Content, Title, Wrapper } from "./styled";

const AlertModal = ({ options, close }) => {
  const { title, text, icon, button, width } = options;

  return (
    <Modal width={width ?? 400} hideCloseButton>
      <Wrapper>
        <Content>
          {icon && (
            <Symbol
              name={icon?.name || icon || "info"}
              variant="contained"
              color={icon?.color || "grey400"}
              fontSize="large"
            />
          )}

          <Title>{title}</Title>
          <Typography variant="body1" color="text.grey700">
            {text}
          </Typography>
        </Content>
        <Buttons direction="vertical">
          <Button
            color={button?.color || "primary"}
            onClick={() => close("confirm")}
            variant="contained"
            size="large"
          >
            {button?.text || "확인했어요"}
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

export default AlertModal;
