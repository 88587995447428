import { createSlice } from "@reduxjs/toolkit";

const initList = {
  list: [],
  paginate: { total_page: 1, page: 1 },
};

export const name = "pay";
const slice = createSlice({
  name,
  initialState: {
    reportList: initList,
    vacsRequestList: initList,
    autoRequestList: initList,
  },
  reducers: {
    getReportList: () => {},
    setReportList: (state, { payload }) => {
      const { list, paginate } = payload;
      const total_page = Math.ceil(paginate?.total / paginate?.page_limit);
      state.reportList = {
        list,
        paginate: { ...paginate, total_page: total_page > 0 ? total_page : 1, page: paginate.page },
      };
    },
    getVacsRequestList: () => {},
    setVacsRequestList: (state, { payload }) => {
      state.vacsRequestList = payload;
    },
    getAutoRequestList: () => {},
    setAutoRequestList: (state, { payload }) => {
      state.autoRequestList = payload;
    },
    createVacs: () => {},
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
