import PropTypes from "prop-types";
import styled from "styled-components";
import Breadcrumbs from "../Breadcrumbs";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  margin: 0px 0px 48px 0px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.palette.grey900.main};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.palette.grey600.main};
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

const PageHeader = ({ crumbs, title, description, children, mainUrl }) => {
  const showCrumbs = Boolean(crumbs?.length);
  const showContent = Boolean(title) || Boolean(description) || Boolean(children);

  return (
    showCrumbs &&
    showContent && (
      <Wrapper>
        {showCrumbs && <Breadcrumbs crumbs={crumbs} mainUrl={mainUrl} />}
        {showContent && (
          <Content>
            {(Boolean(title) || Boolean(description)) && (
              <TextWrapper>
                {Boolean(title) && <Title>{title}</Title>}
                {Boolean(description) && <Description>{description}</Description>}
              </TextWrapper>
            )}
            <Buttons>{children}</Buttons>
          </Content>
        )}
      </Wrapper>
    )
  );
};

PageHeader.propTypes = {
  crumbs: PropTypes.array,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

PageHeader.defaultProps = {
  crumbs: [],
};

export default PageHeader;
