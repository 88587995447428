import { put, call, takeLatest } from "redux-saga/effects";
import { action as payAction } from "../modules/pay";
import { apiRequest } from "../server_url";

function* getReportList(action) {
  try {
    const response = yield call(apiRequest, {
      url: "/pay/report/list/",
      method: "GET",
      params: action.payload,
    });
    yield put(payAction.setReportList(response.data));
  } catch (err) {}
}

function* getVacsRequestList({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/pay/request/list/vacs/",
      method: "GET",
      params: payload,
    });
    yield put(payAction.setVacsRequestList(response.data));
  } catch (err) {}
}

function* getAutoRequestList({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/pay/request/list/cms/",
      method: "GET",
      params: payload,
    });
    yield put(payAction.setAutoRequestList(response.data));
  } catch (err) {}
}

export function* payAsync() {
  yield takeLatest(payAction.getReportList, getReportList);
  yield takeLatest(payAction.getVacsRequestList, getVacsRequestList);
  yield takeLatest(payAction.getAutoRequestList, getAutoRequestList);
}
