import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { Pagination as MuiPagination, PaginationItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../theme/palette";

const StyledComponent = styled(MuiPagination)(() => ({
  "& .MuiPaginationItem-root": {
    display: "flex",
    alignItems: "center",
    minWidth: "28px",
    height: "28px",
    margin: "0 4px",
    fontWeight: "700",
    fontSize: "14px",
    color: colors.grey[500],
    "& svg": {
      fontSize: "20px",
    },
    "&:hover": {
      background: colors.primary[200],
    },
  },
}));

const Pagination = props => (
  <StyledComponent
    count={10}
    showFirstButton
    showLastButton
    shape="rounded"
    color="primary"
    renderItem={item => (
      <PaginationItem
        slots={{
          first: KeyboardDoubleArrowLeftRoundedIcon,
          previous: KeyboardArrowLeftRoundedIcon,
          next: KeyboardArrowRightRoundedIcon,
          last: KeyboardDoubleArrowRightRoundedIcon,
        }}
        {...item}
      />
    )}
    {...props}
  />
);

export default Pagination;
