import { saveExcel } from "helpers/saveExcel";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { action as askAction } from "../modules/ask";
import { action as messageListAction } from "../modules/modal/message_list";
import { action as profileAction } from "../modules/profile";
import { apiRequest } from "../server_url";

const searchData = state => state.ask.search;
function* getAsks(action) {
  try {
    let data = yield select(searchData);
    const response = yield call(apiRequest, {
      url: "/ask/list/",
      method: "GET",
      params: {
        page: action.payload,
        type: data.type,
        subtype: data.subtype,
        keyword: data.keyword,
        start_date: data.startDate,
        end_date: data.endDate,
      },
    });

    yield put(askAction.setAsks({ list: response.data.list, paginate: response.data.paginate }));
  } catch (error) {}
}

function* exportExcel() {
  let data = yield select(searchData);
  try {
    const response = yield call(apiRequest, {
      url: "/ask/list/excel",
      method: "GET",
      responseType: "blob",
      params: {
        type: data.type,
        subtype: data.subtype,
        keyword: data.keyword,
        start_date: data.startDate,
        end_date: data.endDate,
      },
    });

    saveExcel(response.data, `상담문의내역 [${data.startDate}/${data.endDate}]`);
  } catch (error) {}
}

function* toggleStatus(action) {
  try {
    yield call(apiRequest, {
      url: "/ask/status/",
      method: "POST",
      data: {
        id: action.payload,
      },
      loading: false,
    });
  } catch (error) {}
}

function* postRecharge(action) {
  const { id, tr_code, eid } = action.payload;
  let status = 0;

  try {
    yield call(apiRequest, {
      url: "/ask/recharge/",
      method: "POST",
      data: {
        id,
        tr_code,
        eid,
      },
    });

    alert("재충전이 완료되었습니다.");
    yield put(profileAction.getProfile());
    status = 9;
  } catch (error) {
    status = 8;
  }

  yield put(askAction.setRecharge({ id, tr_code, status }));
}

// modal
function* sendMessage(action) {
  try {
    let { id, phone, fromphone, msg } = action.payload;
    yield call(apiRequest, {
      url: "/ask/absence/message",
      method: "POST",
      data: {
        id,
        phone,
        fromphone,
        msg,
      },
      errororAlert: false,
    });

    alert("성공적으로 문자가 전송되었습니다.");
    yield put(askAction.addMessageCount(id));
  } catch (error) {
    alert("문자 전송 중 에러가 발생하였습니다.");
  }
}

function* getMessageList(action) {
  try {
    const response = yield call(apiRequest, {
      url: "/ask/absence/message",
      method: "GET",
      params: {
        id: action.payload,
      },
    });

    yield put(messageListAction.open(response.data));
  } catch (error) {}
}

function* postMemo(action) {
  try {
    let { id, memo } = action.payload;
    yield call(apiRequest, {
      url: "/ask/cs/message",
      method: "POST",
      data: {
        id,
        memo,
      },
    });

    yield put(askAction.setMemo({ id, memo }));
  } catch (error) {}
}

export function* askAsync() {
  yield takeLatest(askAction.getAsks, getAsks);
  yield takeLatest(askAction.exportExcel, exportExcel);
  yield takeLatest(askAction.toggleStatus, toggleStatus);
  yield takeLatest(askAction.postRecharge, postRecharge);
  yield takeLatest(askAction.sendMessage, sendMessage);
  yield takeLatest(messageListAction.getList, getMessageList);
  yield takeLatest(askAction.postMemo, postMemo);
}
