import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

export const name = "alert";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    add: (state, { payload }) => {
      state.list.push({ ...payload });
    },
    pop: state => {
      state.list.pop();
    },
    clear: state => {
      state.list = [];
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
