import React from "react";
import { Close } from "@mui/icons-material";
import styled from "styled-components";
import Modal, { ScrollBody, Title } from "components/modals/base/Modal";
import { FlexEnd } from "components/common/Flex";
import { Button } from "@mui/material";

const ModalContent = styled.div``;

const Body = styled.div`
  padding: 0px 10px 20px 20px;

  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #484760;
  text-align: center;
`;

const CustomModal = ({ id, title, width, children, button, close, hideCloseButton = false }) => {
  return (
    <Modal id={id} width={width ? width : 334} close={close}>
      <ModalContent>
        <div style={{ padding: "20px 20px 0px 20px" }}>
          <Title style={{ marginBottom: "16px" }}>
            {title}
            {!hideCloseButton && (
              <Close
                onClick={() => close()}
                style={{
                  fontSize: "22px",
                  color: "#7E7E8F",
                  position: "absolute",
                  top: "2px",
                  right: "0px",
                  cursor: "pointer",
                }}
              />
            )}
          </Title>
        </div>
        <ScrollBody>
          <Body>{children}</Body>
        </ScrollBody>
        {Boolean(button) && (
          <FlexEnd style={{ marginTop: 24 }}>
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              onClick={button?.cancel?.onClick ? button.cancel.onClick : () => close("cancel")}
            >
              {button?.cancel?.text || "취소"}
            </Button>
            <Button
              size="large"
              variant="contained"
              color={button?.confirm?.color || "primary"}
              onClick={() => {
                button?.onClick && button.onClick();
                button?.confirm?.onClick && button?.confirm.onClick();
                close("confirm");
              }}
            >
              {button?.confirm?.text || "확인"}
            </Button>
          </FlexEnd>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
