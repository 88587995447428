import PropTypes from "prop-types";
import styled from "styled-components";

const VerticalDivider = styled.div`
  width: ${({ width }) => width || "1px"};
  height: ${({ height }) => height || "28px"};
  margin: 0px 4px;
  background: ${({ bg }) => bg || "#dadadf"};
`;

const HorizontalDivider = styled.div`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "1px"};
  margin: 4px 0px;
  background: ${({ bg }) => bg || "#dadadf"};
`;

const Divider = ({ variant, ...props }) => {
  return variant === "vertical" ? <VerticalDivider {...props} /> : <HorizontalDivider {...props} />;
};

Divider.propTypes = {
  variant: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Divider.defaultProps = {
  variant: "vertical",
};

export default Divider;
