import { Typography } from "@mui/material";
import styled from "styled-components";
import { CustomCloseCircleIcon } from "../Icons";
import BaseModal from "./BaseModal";
import { Flex } from "./styled";

const ModalContent = styled.div`
  padding: 32px 32px 36px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  width: 100%;
  margin-bottom: 16px;
  ${Flex} {
    width: 100%;
    justify-content: space-between;
  }
  h2 {
    display: block;
    width: 100%;
    line-height: 150%;
  }
`;

const SubTitle = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.grey600.main};
  font-size: 13px;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
  text-align: inherit;
  p {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  text-align: left;
`;

export const ScrollContent = styled.div`
  width: calc(100% + 25px);
  padding-right: 11px;

  overflow-y: scroll;
  overflow-x: clip;

  // 80px: 모달 상하단 margin
  // 68px: 모달 상하단 padding
  // 50px: 모달 타이틀 영역
  // 72px: 모달 버튼 영역
  max-height: calc(
    100vh - 80px ${({ fixedHeight }) => `- ${fixedHeight}px` ?? "- 68px"} - 50px - 72px
  );
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: 8px;

  padding-top: 24px;

  & > * {
    flex: 1 1 0;
  }
`;

export const Body = ({ children }) => (
  <Typography
    sx={{
      width: "-webkit-fill-available",
      padding: "16px",
      border: 1,
      borderColor: "grey100.main",
      backgroundColor: "grey100.main",
      textAlign: "left",
      borderRadius: "8px",
    }}
  >
    {children}
  </Typography>
);

const Modal = ({
  id,
  title,
  titleAlign,
  subTitle,
  width,
  children,
  close,
  hideCloseButton = false,
  buttonDirection = "row",
  buttons,
  ...props
}) => {
  return (
    <BaseModal id={id} width={width ? width : 360} close={close} {...props}>
      <ModalContent>
        {(Boolean(title) || !hideCloseButton) && (
          <Header style={{ textAlign: titleAlign ?? "left" }}>
            <Flex>
              <Typography variant="h2" color="text.grey900">
                {title}
              </Typography>
              {!hideCloseButton && (
                <CustomCloseCircleIcon
                  color="grey500"
                  onClick={() => close()}
                  style={{
                    cursor: "pointer",
                  }}
                />
              )}
            </Flex>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
          </Header>
        )}
        <Content>{children}</Content>
        {Boolean(buttons) && <Buttons direction={buttonDirection}>{buttons}</Buttons>}
      </ModalContent>
    </BaseModal>
  );
};

export default Modal;
