import React from "react";
import moment from "moment";

export const dateFormat = (date, format = "YYYY년 MM월 DD일") => {
  return moment(date).format(format);
};

export const dateTimeFormat = (date, format = "YYYY년 MM월 DD일 HH:mm") => {
  return moment(date).format(format);
};

export const priceFormat = (price, showZero = false) => {
  let price_text;

  const isNegative = price < 0;
  if (isNegative) price *= -1;

  if (price >= 100000000) price_text = `${Math.floor((price / 100000000) * 10) / 10}억원`;
  else if (price >= 10000) price_text = `${Math.floor((price / 10000) * 10) / 10}만원`;
  else if (price >= 1000) price_text = `${Math.floor((price / 1000) * 10) / 10}천원`;
  else if (!price && !showZero) price_text = "";
  else price_text = `${price}원`;

  return isNegative ? `-${price_text}` : price_text;
};

export const priceNumberFormat = priceString => {
  if (!priceString) return;
  priceString = `${priceString}`;
  let text_num = priceString.replace(/[^0-9.]/g, "");
  if (priceString.includes("천원")) text_num *= 1000;
  else if (priceString.includes("만원")) text_num *= 10000;
  else if (priceString.includes("억원")) text_num *= 100000000;

  return text_num;
};

export const priceToString = priceFormat;
export const stringToPrice = priceNumberFormat;

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
export const stringToPascalCase = str => str.charAt(0).toUpperCase() + str.slice(1);

export const removeEmoji = str =>
  str?.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ""
  );

export const onlyKeyboardText = str =>
  removeEmoji(str)?.replace(
    /[^ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9~!@#$%^&*()_+=\-`\n{}|\\;:'"<>?,./\]/[ ]/g,
    ""
  );

export const newlineToDiv = str => {
  const arr = str?.split("\n");
  return arr?.map((text, index) => (
    <div key={`${index}_${text}`}>
      {text}
      {Boolean(index > 0 && index < arr.length) && <br />}
    </div>
  ));
};
