import { put, call, takeLatest } from "redux-saga/effects";
import { apiRequest } from "server_url";
import { action as prepaymentPaymentAction } from "modules/prepaymentPayment";
import { action as messageListAction } from "modules/modal/message_list";
import { saveExcel } from "helpers/saveExcel";

function* getList({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/payment/list/",
      method: "GET",
      params: {
        search_word: payload?.keyword,
        start_date: payload?.startDate,
        end_date: payload?.endDate,
        page: payload?.page,
        page_offset: 10,
      },
    });

    yield put(prepaymentPaymentAction.setList(response.data));
  } catch (error) {}
}

function* exportExcel({ payload }) {
  const { startDate, endDate } = payload;
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/payment/list/excel/",
      method: "GET",
      responseType: "blob",
      params: {
        search_word: payload?.keyword,
        start_date: startDate,
        end_date: endDate,
      },
    });

    saveExcel(
      response.data,
      `미리결제 판매 내역 [${startDate} - ${endDate}]${
        payload?.keyword ? `_${payload?.keyword}` : ""
      }`
    );
  } catch (error) {}
}

function* getCouponBookList({ payload }) {
  const { data, callback } = payload;
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/payment/couponbook/list/",
      method: "GET",
      params: data,
    });

    yield put(prepaymentPaymentAction.setCouponBookList(response.data));
    callback && callback(response.data);
  } catch (error) {}
}

function* getCouponList({ payload }) {
  const { data, callback } = payload;
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/payment/couponbook/coupon/list/",
      method: "GET",
      params: data,
    });

    yield put(prepaymentPaymentAction.setCouponList(response.data));
    callback && callback(response.data);
  } catch (error) {}
}

function* useCoupon({ payload }) {
  const { data, callback } = payload;
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/payment/couponbook/coupon/use/",
      method: "PUT",
      data,
      errorAlert: false,
    });

    callback(response.data);
  } catch (error) {
    callback();
  }
}

function* toggleStatus({ payload }) {
  try {
    yield call(apiRequest, {
      url: "/prepayment_event/payment/status/",
      method: "PUT",
      data: {
        payment_id: payload,
      },
    });

    yield put(prepaymentPaymentAction.toggleStatusSuccess(payload));
  } catch (error) {}
}

function* putMemo({ payload }) {
  const { id, memo } = payload;
  try {
    yield call(apiRequest, {
      url: "/prepayment_event/payment/cs/message/",
      method: "PUT",
      data: {
        payment_id: id,
        memo,
      },
    });

    yield put(prepaymentPaymentAction.putMemoSuccess(payload));
  } catch (error) {}
}

function* getMessageList({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/payment/absence/message/",
      method: "GET",
      params: {
        payment_id: payload,
      },
    });

    yield put(messageListAction.open(response.data));
  } catch (error) {}
}

function* sendMessage({ payload }) {
  const { id, phone, fromphone, msg } = payload;
  try {
    yield call(apiRequest, {
      url: "/prepayment_event/payment/absence/message/",
      method: "POST",
      data: {
        payment_id: id,
        receiver_phone: phone,
        sender_phone: fromphone,
        msg,
      },
    });

    yield put(prepaymentPaymentAction.sendMessageSuccess(id));
  } catch (error) {}
}

export function* async() {
  yield takeLatest(prepaymentPaymentAction.getList, getList);
  yield takeLatest(prepaymentPaymentAction.exportExcel, exportExcel);

  yield takeLatest(prepaymentPaymentAction.getCouponBookList, getCouponBookList);
  yield takeLatest(prepaymentPaymentAction.getCouponList, getCouponList);
  yield takeLatest(prepaymentPaymentAction.useCoupon, useCoupon);

  yield takeLatest(prepaymentPaymentAction.toggleStatus, toggleStatus);
  yield takeLatest(prepaymentPaymentAction.putMemo, putMemo);

  yield takeLatest(prepaymentPaymentAction.getMessageList, getMessageList);
  yield takeLatest(prepaymentPaymentAction.sendMessage, sendMessage);
}
