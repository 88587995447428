import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "stop",
  seconds: 0,
};

export const name = "timer";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    start: (state, action) => {
      state.seconds = action.payload;
    },
    stop: state => {
      state.status = "stop";
    },
    timeOver: state => {
      state.status = "timeOver";
    },
    watch: state => {
      state.status = "play";
    },
    decrease: state => {
      state.seconds--;
    },
    setSeconds: (state, action) => {
      state.seconds = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
