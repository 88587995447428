import { put, call, takeLatest } from "redux-saga/effects";
import { action as profileAction } from "../modules/profile";
import { apiRequest } from "../server_url";

function* editTelephone(action) {
  const { api, params, onAction } = action.payload;
  try {
    yield call(apiRequest, {
      url: api,
      method: "POST",
      data: params,
    });
    yield put(profileAction.setProfile(params));
    alert("등록이 완료 되었습니다.");
    onAction();
  } catch (err) {}
}

function* deleteTelephone(action) {
  const { api, target } = action.payload;
  try {
    yield call(apiRequest, {
      url: api,
      method: "DELETE",
      data: target ? { [target]: true } : {},
    });
    yield put(profileAction.setProfile({ [target]: "" }));
  } catch (err) {}
}

export function* telephoneAsync() {
  yield takeLatest(profileAction.editProfile, editTelephone);
  yield takeLatest(profileAction.deleteProfile, deleteTelephone);
}
