import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ImageModal = ({ options, close }) => {
  const [images, setImages] = useState([""]);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    options.images && setImages(options.images);
    options.index && setPhotoIndex(options.index);

    return () => {
      setImages([""]);
      setPhotoIndex(0);
    };
  }, [options]);

  return (
    <Lightbox
      mainSrc={images[photoIndex]}
      nextSrc={images.length > 1 ? images[(photoIndex + 1) % images.length] : null}
      prevSrc={images.length > 1 ? images[(photoIndex + images.length - 1) % images.length] : null}
      onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
      onCloseRequest={close}
      animationDuration={200}
      imagePadding={50}
      reactModalStyle={{ overlay: { zIndex: 1300 } }}
    />
  );
};

export default ImageModal;
