export const getDiscountRate = (price, discountedPrice, fixed = 0) => {
  return (100 - (discountedPrice / price) * 100).toFixed(fixed).replace(/.0$/, "");
};

export const getDiscountedPrice = (price, rate) => {
  if (rate > 0) {
    return price * (1 - parseInt(rate) / 100);
  }
  return price;
};

export const getCutPrice = (price, unit = 1000) => {
  if (price <= 0) return 0;
  while (price < unit) price = price * 10;
  return Math.floor(price / unit) * unit;
};

export const prepaymentPriceFormat = (price, showZero = false) => {
  let price_text;

  const isNegative = price < 0;
  if (isNegative) price *= -1;

  if (!price && !showZero) price_text = "";
  else {
    price_text = price / 10000;
    price_text += "만원";
  }

  return isNegative ? `-${price_text}` : price_text;
};
