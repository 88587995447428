import { put, call, takeLatest } from "redux-saga/effects";
import { apiRequest } from "../server_url";
import { action as accountAction } from "../modules/account";

function* getTokenExpire() {
  try {
    const response = yield call(apiRequest, {
      url: "/login/token/expire/until/",
      method: "GET",
    });
    yield put(accountAction.setTokenExpire(response.data.token_expire_until));
  } catch (error) {}
}

function* changeTokenExpire({ payload }) {
  try {
    yield call(apiRequest, {
      url: "/login/token/expire/until/",
      method: "POST",
      data: { token_expire_until: payload },
      loading: false,
    });
    yield put(accountAction.setTokenExpire(payload));
    alert("로그인 세션 유효시간이 변경되었습니다.");
  } catch (error) {}
}

function* changePassword({ payload }) {
  try {
    yield call(apiRequest, {
      url: "/login/change/password/",
      method: "POST",
      data: {
        old_pw: payload.oldPw,
        new_pw: payload.newPw,
        new_pw_check: payload.newPwCheck,
      },
    });
    alert("비밀번호가 변경되었습니다.");
    payload.onAction();
  } catch (error) {}
}

function* getLoginPhone() {
  try {
    const response = yield call(apiRequest, {
      url: "/login/two/factor/",
      method: "GET",
    });
    yield put(accountAction.setLoginPhone(response.data.login_phone));
  } catch (error) {}
}

function* postLoginPhone({ payload }) {
  try {
    yield call(apiRequest, {
      url: "/login/two/factor/",
      method: "POST",
      data: { login_phone: payload.phoneNumber },
    });
    yield put(accountAction.setLoginPhone(payload.phoneNumber));
    payload.onAction();
  } catch (error) {}
}

function* deleteLoginPhone() {
  try {
    yield call(apiRequest, {
      url: "/login/two/factor/",
      method: "DELETE",
    });
    yield put(accountAction.setLoginPhone(null));
  } catch (error) {}
}

export function* accountAsync() {
  yield takeLatest(accountAction.getTokenExpire, getTokenExpire);
  yield takeLatest(accountAction.changeTokenExpire, changeTokenExpire);
  yield takeLatest(accountAction.changePassword, changePassword);
  yield takeLatest(accountAction.getLoginPhone, getLoginPhone);
  yield takeLatest(accountAction.postLoginPhone, postLoginPhone);
  yield takeLatest(accountAction.deleteLoginPhone, deleteLoginPhone);
}
