import { put, call, takeLatest } from "redux-saga/effects";
import { action as profileAction } from "../modules/profile";
import { apiRequest } from "../server_url";

function* getProfile({ payload }) {
  try {
    let result = [];
    result[0] = yield call(apiRequest, {
      url: "/client/profile/",
      method: "GET",
    });
    result[1] = yield call(apiRequest, {
      url: "/phone/local/",
      method: "GET",
    });

    yield put(profileAction.setProfile({ ...result[0].data, ...result[1].data }));
    payload.successCallback && payload.successCallback();
  } catch (err) {}
}

function* getMoney() {
  try {
    let result = yield call(apiRequest, {
      url: "/client/money/",
      method: "GET",
    });
    yield put(profileAction.changeMoneyProfile(result.data[0].profile));
  } catch (err) {}
}

export function* profileAsync() {
  yield takeLatest(profileAction.getProfile, getProfile);
  yield takeLatest(profileAction.getMoney, getMoney);
}
