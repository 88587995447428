import { put, call, takeLatest } from "redux-saga/effects";
import { apiRequest } from "../server_url";
import { action as doctorAction } from "../modules/doctor";

function* getDoctorList() {
  try {
    const response = yield call(apiRequest, {
      url: "/doctor/list/",
      method: "GET",
    });
    yield put(doctorAction.setDoctorList({ live: response.data.live, check: response.data.check }));
  } catch (err) {}
}

function* changeDoctorOrder(action) {
  const doctors = action.payload;
  const orderDoctors = doctors.reduce((map, obj, index) => {
    map[obj.id] = index + 1;
    return map;
  }, {});

  try {
    yield call(apiRequest, {
      url: "/doctor/sort/priority/",
      method: "POST",
      data: { doctor_priority: orderDoctors },
    });
  } catch (err) {}
}

function* cancelCheckupDoctor(action) {
  const doctorId = action.payload.id;
  const type = action.payload.type;
  try {
    yield call(apiRequest, {
      url: "/doctor/check/up/cancel/",
      method: "POST",
      data: { id: doctorId },
    });
    yield put(doctorAction.updateCheckupDoctor({ doctorId: doctorId }));
    if (type === "cancel") alert("검수취소 되었습니다.");
    if (type === "delete") alert("삭제 되었습니다.");
    action.payload.onAction();
  } catch (err) {}
}

function* getLicenceCode(action) {
  const { code, onAction } = action.payload;
  try {
    const response = yield call(apiRequest, {
      url: "/doctor/check/licence/",
      method: "GET",
      params: { licence_code: code },
    });
    yield put(doctorAction.setLicenceCode(code));
    onAction(response.data);
  } catch (err) {}
}

function* disconnectDoctor(action) {
  const doctorId = action.payload;
  try {
    yield call(apiRequest, {
      url: "/doctor/disconnect/hospital/",
      method: "POST",
      data: { id: doctorId },
    });
    yield put(doctorAction.updateDisconnectDoctor({ doctorId: doctorId }));
    alert("정상 연결해제 되었습니다.");
  } catch (err) {}
}

function* getCategories() {
  try {
    const response = yield call(apiRequest, {
      url: "/doctor/v2/treatment/categories/",
      method: "GET",
    });
    yield put(doctorAction.setCategories(response.data));
  } catch (err) {}
}

function* getSpecialistCategories() {
  try {
    const response = yield call(apiRequest, {
      url: "/doctor/specialist/categories/",
      method: "GET",
    });
    yield put(doctorAction.setSpecialistCategories(response.data));
  } catch (err) {}
}

export function* doctorAsync() {
  yield takeLatest(doctorAction.getDoctorList, getDoctorList);
  yield takeLatest(doctorAction.changeDoctorOrder, changeDoctorOrder);
  yield takeLatest(doctorAction.cancelCheckupDoctor, cancelCheckupDoctor);
  yield takeLatest(doctorAction.getLicenceCode, getLicenceCode);
  yield takeLatest(doctorAction.disconnectDoctor, disconnectDoctor);

  yield takeLatest(doctorAction.getCategories, getCategories);
  yield takeLatest(doctorAction.getSpecialistCategories, getSpecialistCategories);
}
