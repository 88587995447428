import { dataURLtoFile } from "helpers/base64_to_file";
import { action as hospitalAction } from "modules/hospital";
import { serialize } from "object-to-formdata";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiRequest } from "server_url";

function* getHospitalInfo(action) {
  try {
    const response = yield call(apiRequest, {
      url: "/hospital/",
      method: "GET",
      params: action.payload,
    });
    yield put(hospitalAction.getBankInfo(response.data));
  } catch (error) {}
}

function* postHospitalInfo({ payload }) {
  const { data, callback, surgeryCategories, treatmentsCategories } = payload;
  const surgery_main_category_names = data.main_categories.filter(cate =>
    surgeryCategories.find(item => item.name === cate)
  );
  const treatments_main_category_names = data.main_categories.filter(cate =>
    treatmentsCategories.find(item => item.name === cate)
  );

  let images = ["limg", "img", 1, 2, 3, 4, 5, 6].reduce((acc, current) => {
    const name = typeof current === "string" ? current : `img${current}`;
    const image = data[name];
    if (image.startsWith("data:image/")) {
      acc = { ...acc, [name]: dataURLtoFile(image, name) };
      delete data[name];
    }
    return acc;
  }, {});

  try {
    yield call(apiRequest, {
      url: "/hospital/",
      method: "POST",
      data: serialize({
        json_data: JSON.stringify({
          ...data,
          ...data.work_hour,
          is_parking: Boolean(data.is_parking),
          youtube_ch_id: data.youtube_channel_id,
          surgery_category_names: data.categories
            ?.filter(cate => surgeryCategories.find(item => item.name === cate))
            ?.filter(cate => !surgery_main_category_names.find(item => item === cate)),
          surgery_main_category_names,
          treatments_category_names: data.categories
            ?.filter(cate => treatmentsCategories.find(item => item.name === cate))
            ?.filter(cate => !treatments_main_category_names.find(item => item === cate)),
          treatments_main_category_names,
        }),
        ...images,
      }),
    });
    yield put(hospitalAction.getHospitalInfo());
    callback && callback();
  } catch (error) {
    yield put(hospitalAction.resetYoutubeId());
  }
}

function* getBankInfo({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/hospital/account/",
      method: "GET",
    });
    yield put(hospitalAction.setHospitalInfo({ ...payload, ...response.data }));
  } catch (error) {
    yield put(hospitalAction.setHospitalInfo({ ...payload }));
  }
}

function* postBankInfo({ payload }) {
  const { data, callback } = payload;
  try {
    yield call(apiRequest, {
      url: "/hospital/account/",
      method: "POST",
      data,
    });

    callback();
  } catch (error) {}
}

function* getCategories() {
  try {
    const response = yield call(apiRequest, {
      url: "/hospital/v2/treatment/categories/",
      method: "GET",
    });
    yield put(hospitalAction.setCategories(response.data));
  } catch (err) {}
}

export function* hospitalAsync() {
  yield takeLatest(hospitalAction.getHospitalInfo, getHospitalInfo);
  yield takeLatest(hospitalAction.postHospitalInfo, postHospitalInfo);

  yield takeLatest(hospitalAction.getBankInfo, getBankInfo);
  yield takeLatest(hospitalAction.postBankInfo, postBankInfo);

  yield takeLatest(hospitalAction.getCategories, getCategories);
}
