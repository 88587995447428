export const colors = {
  primary: {
    100: "#F8F8FF",
    200: "#F4F3FF",
    300: "#E6E3FF",
    400: "#D0CBFF",
    500: "#BEB8FF",
    600: "#897DFF",
    700: "#7264FF",
    800: "#604AFF",
    900: "#5336FF",
  },
  grey: {
    0: "#FCFCFC",
    100: "#F7F7F9",
    150: "#F1F1F4",
    200: "#ECECEF",
    300: "#DADADF",
    400: "#C8C7CF",
    500: "#A3A3AF",
    600: "#7E7E8F",
    700: "#605F76",
    800: "#484760",
    900: "#373649",
    1000: "#313142",
    1100: "#272633",
    1200: "#1C1B25",
    1300: "#101016",
  },
  yellow: {
    100: "#FFF8DE",
    200: "#FFF080",
    300: "#FFDD55",
    400: "#FFCC00",
    500: "#FFA100",
    600: "#FF8300",
  },
  green: {
    100: "#DFFFF0",
    200: "#80EEBB",
    300: "#45D2A0",
    400: "#00C27C",
    500: "#009660",
    600: "#00955D",
  },
  red: {
    100: "#FFDDDD",
    200: "#FFA4AA",
    300: "#FB747D",
    400: "#FB545E",
    500: "#F82D3E",
    600: "#C2202E",
  },
  treatment: {
    100: "#FFF9FB",
    200: "#FFF2F6",
    300: "#FFDEE9",
    400: "#FFC3D8",
    500: "#FF9BBE",
    600: "#FF79A7",
    700: "#FF6B9E",
    800: "#FF528D",
    900: "#FF387D",
  },
  community: {
    100: "#F6FAFF",
    200: "#EEF5FF",
    300: "#BED8FE",
    400: "#93BFFE",
    500: "#7DB2FD",
    600: "#408EFD",
    700: "#277FFD",
    800: "#0E70FD",
    900: "#0065F5",
  },
};

export const gradation = {
  gradationBoosting: "linear-gradient(to right, #5336FF 0%, #FF528D 100%)",
};

let colorsObj = {};
let textColorsObj = {};
Object.keys(colors)?.map(color =>
  Object.keys(colors[color])?.map(weight => {
    colorsObj = {
      ...colorsObj,
      [color + weight]: { main: colors[color][weight] },
    };

    textColorsObj = {
      ...textColorsObj,
      [color + weight]: colors[color][weight],
    };
  })
);
export const colorNames = Object.keys(colorsObj);

const tertiary = {
  // admin, success
  dark: "#009660",
  main: "#00C27C",
  light: "#45D2A0",
  contrastText: "#FFFFFF",
};

const danger = {
  // error, red
  dark: "#F82D3E",
  main: "#FB545E",
  light: "#FFA4AA",
  contrastText: "#FFFFFF",
};

const palette = {
  ...colorsObj,
  primary: {
    dark: "#604AFF",
    main: "#7264FF",
    light: "#BEB8FF",
    contrastText: "#fff",
  },
  secondary: {
    // grey
    dark: colors.grey[900],
    main: colors.grey[800],
    light: colors.grey[700],
    contrastText: "#FFFFFF",
  },
  tertiary,
  success: tertiary,
  admin: tertiary,
  danger,
  error: danger,
  warning: {
    // yeloow
    dark: "#FFA100",
    main: "#FFCC00",
    light: "#FFF080",
    contrastText: "#FFFFFF",
  },
  info: {
    dark: "#A3A3AF",
    main: "#C8C7CF",
    light: "#ECECEF",
    contrastText: "#FFFFFF",
  },
  white: { main: "#ffffff" },
  black: { main: "#000000" },
  // gradient colors
  gradationBenefit: {
    dark: "#F25EFF",
    main: "#FF2F76",
    light: "#FFA1C2",
    contrastText: "#fff",
  },
  // 태그에서 사용하는 컬러셋
  chip: {
    primary: {
      dark: "#604AFF",
      main: "#7264FF",
      light: "#BEB8FF",
      contrastText: "#fff",
      border: "#E6E3FF",
    },
    secondary: {
      // grey
      dark: "#9797a5",
      main: "#7E7E8F",
      light: "#9797a5",
      contrastText: "#FFFFFF",
      border: "#DADADF",
    },
    tertiary: {
      // pink
      dark: "#b24a6e",
      main: "#FF6B9E",
      light: "#ff88b1",
      contrastText: "#FFFFFF",
      border: "#FFDEE9",
    },
    red: {
      dark: colors.red[600],
      main: colors.red[500],
      light: colors.red[400],
      contrastText: "#FFFFFF",
      border: colors.red[200],
    },
    green: {
      dark: colors.green[500],
      main: colors.green[400],
      light: colors.green[300],
      contrastText: "#FFFFFF",
      border: colors.green[300],
    },
    yellow: {
      dark: colors.yellow[600],
      main: colors.yellow[500],
      light: colors.yellow[400],
      contrastText: "#FFFFFF",
      border: colors.yellow[300],
    },
    info: {
      dark: colors.grey[0],
      main: colors.grey[100],
      light: colors.grey[150],
      contrastText: colors.grey[600],
    },
    gradationBoosting: { main: "#FFFFFF" },
  },
  text: {
    primary: "#484760",
    // secondary: "#605F76",
    disabled: "#A3A3AF",
    hint: "#7E7E8F",
    white: "#fff",
    black: "#000",
    ...textColorsObj,
  },
  background: {
    default: "#F7F7F9",
  },
};

export default palette;
