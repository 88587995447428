import { createSlice } from "@reduxjs/toolkit";
import { listInitialState } from "./common/initialState";

const initialState = {
  ...listInitialState,
  couponBookList: [],
  couponList: [],
};

export const name = "prepaymentPayment";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getList: () => {},
    setList: (state, { payload }) => {
      state.list = payload.list;
      state.paginate = payload.paginate;
    },
    exportExcel: () => {},

    getCouponBookList: () => {},
    setCouponBookList: (state, { payload }) => {
      state.couponBookList = payload;
    },
    getCouponList: () => {},
    setCouponList: (state, { payload }) => {
      state.couponList = payload;
    },
    useCoupon: () => {},

    toggleStatus: () => {},
    toggleStatusSuccess: (state, { payload }) => {
      const find = state.list.find(item => item.payment_id === payload);
      if (find) {
        find.cs_status = (parseInt(find.cs_status) + 1) % 2;
      }
    },

    putMemo: () => {},
    putMemoSuccess: (state, { payload }) => {
      const { id, memo } = payload;
      const find = state.list.find(item => item.payment_id === id);
      if (find) {
        find.memo = memo;
      }
    },

    getMessageList: () => {},
    sendMessage: () => {},
    sendMessageSuccess: (state, { payload }) => {
      const find = state.list.find(item => item.payment_id === payload);
      if (find) {
        find.sms_count++;
      }
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
