import { action as sidebarAction } from "modules/common/sidebar";
import { serialize } from "object-to-formdata";
import { call, put, takeLatest } from "redux-saga/effects";
import { action as profileAction } from "../modules/profile";
import { action as pushPopupAction } from "../modules/pushPopup";
import { apiRequest } from "../server_url";

// ===================
// 푸시/팝업 구매 관련
function* getCalendarList(action) {
  const { type, params } = action.payload;
  let url;
  switch (type) {
    case "pushPopup":
      url = "/advertise/push/popup/list/";
      break;
    case "motion":
      url = "/advertise/motion/list/";
      break;
    case "wide":
      url = "/advertise/wide/list/";
      break;

    default:
      break;
  }
  try {
    const response = yield call(apiRequest, {
      url,
      method: "GET",
      params,
    });

    // 11월 13일 임시처리, 추후 코드 제거(교체) 필요
    yield put(pushPopupAction.setCalendar(response.data.filter(v => v.date !== "2023-11-13")));
    // yield put(pushPopupAction.setCalendar(response.data));
  } catch (error) {}
}

function* purchase(action) {
  const { type, isCheck, params, openPurchaseModal, onPurchase } = action.payload;
  let isSoldout = false;
  let owned = false;
  let url;

  switch (type) {
    case "pushPopup":
      url = "/advertise/push/popup/purchase/";
      break;
    case "motion":
      url = "/advertise/motion/purchase/";
      break;
    case "wide":
      url = "/advertise/wide/purchase/";
      break;

    default:
      break;
  }

  try {
    const response = yield call(apiRequest, {
      url,
      errorAlert: false,
      method: isCheck ? "GET" : "POST",
      [isCheck ? "params" : "data"]: params,
    });

    if (!isCheck) {
      isSoldout = true;
      owned = true;
      yield put(profileAction.getProfile());
      // 추후 쿠폰 여러개 설정 가능할 경우 list를 불러오도록 수정 필요
      yield put(pushPopupAction.removeCouponData());
    }
    openPurchaseModal({
      ...params,
      ...response.data,
      isCheck,
      onPurchase,
    });
  } catch (error) {
    isSoldout = error.response.data.is_aleady_purchased || error.response.data.is_not_ready;
    openPurchaseModal({
      ...error.response.data,
      isFailure: true,
    });
  }

  if (isSoldout) {
    yield put(pushPopupAction.updateCalendar({ ...params, owned }));
  }
}

// =====================
// 푸시/팝업 리스트 관련
function* getList(action) {
  const { type, params } = action.payload;
  let url;
  switch (type) {
    case "pushPopup":
      url = "/advertise/push/popup/event/list/";
      break;
    case "motion":
      url = "/advertise/motion/event/list/";
      break;
    case "wide":
      url = "/advertise/wide/event/list/";
      break;

    default:
      break;
  }
  try {
    const response = yield call(apiRequest, {
      url,
      method: "GET",
      params,
    });

    yield put(pushPopupAction.setList(response.data));
  } catch (error) {}
}

function* getRejectMessage(action) {
  const { type, params, openRejectModal } = action.payload;
  let url;
  switch (type) {
    case "pushPopup":
      url = "/advertise/push/popup/event/reject/";
      break;
    case "motion":
      url = "/advertise/motion/event/reject/";
      break;
    case "wide":
      url = "/advertise/wide/event/reject/";
      break;

    default:
      break;
  }

  try {
    const response = yield call(apiRequest, {
      url,
      method: "GET",
      params,
    });

    if (response.data.scmt) {
      openRejectModal(response.data.scmt);
    } else {
      alert("반려사유가 없습니다.");
    }
  } catch (error) {}
}

function* postRefund(action) {
  const { type, params, openRefundModal } = action.payload;
  let url;
  switch (type) {
    case "pushPopup":
      url = "/advertise/push/popup/event/refund/";
      break;
    case "motion":
      url = `/advertise/motion/event/refund/`;
      break;
    case "wide":
      url = `/advertise/wide/event/refund/`;
      break;

    default:
      break;
  }

  try {
    const response = yield call(apiRequest, {
      url,
      method: "POST",
      data: params,
    });

    yield put(pushPopupAction.refundCompleted(params.id));
    yield put(profileAction.getProfile());
    yield put(sidebarAction.getPushPopupCount());
    openRefundModal(response.data);
  } catch (error) {}
}

// ========================
// 푸시/팝업 등록/수정 관련
function* getDetail(action) {
  const { type, params, onCallback } = action.payload;
  let url;
  switch (type) {
    case "pushPopup":
      url = "/advertise/push/popup/event/detail/";
      break;
    case "motion":
      url = "/advertise/motion/event/detail/";
      break;
    case "wide":
      url = "/advertise/wide/event/detail/";
      break;

    default:
      break;
  }

  try {
    const response = yield call(apiRequest, {
      url,
      method: "GET",
      params,
      errorAlert: false,
    });

    yield put(pushPopupAction.setDetail(response.data));
  } catch (error) {
    onCallback();
  }
}

function* postDetail(action) {
  const { type, data, onCallback } = action.payload;
  let url;
  switch (type) {
    case "pushPopup":
      url = "/advertise/push/popup/event/detail/";
      break;
    case "motion":
      url = `/advertise/motion/event/detail/`;
      break;
    case "wide":
      url = `/advertise/wide/event/detail/`;
      break;

    default:
      break;
  }

  try {
    yield call(apiRequest, {
      url,
      method: "POST",
      data: serialize(data),
      headers: { "Content-Type": "multipart/form-data" },
    });

    onCallback();
  } catch (error) {}
}

// ========================
// 카테고리 정보 (5.47 부위 정보 + 6.0 서비스 정보)
function* getCategories() {
  try {
    const response = yield call(apiRequest, {
      url: "/advertise/push/popup/category/list/",
      method: "GET",
      errorAlert: false,
    });

    yield put(pushPopupAction.setCategories(response.data));
  } catch (error) {}
}

export function* async() {
  // 푸시/팝업 구매 관련
  yield takeLatest(pushPopupAction.getCalendar, getCalendarList);
  yield takeLatest(pushPopupAction.purchase, purchase);

  // 푸시/팝업 리스트 관련
  yield takeLatest(pushPopupAction.getList, getList);
  yield takeLatest(pushPopupAction.getRejectMessage, getRejectMessage);
  yield takeLatest(pushPopupAction.postRefund, postRefund);

  // 푸시/팝업 등록/수정 관련
  yield takeLatest(pushPopupAction.getDetail, getDetail);
  yield takeLatest(pushPopupAction.postDetail, postDetail);

  // 카테고리 리스트
  yield takeLatest(pushPopupAction.getCategories, getCategories);
}
