import { combineReducers } from "@reduxjs/toolkit";
import * as modal from "./modal";
import * as alert from "./alert";
import * as sidebar from "./common/sidebar";
import * as account from "./account";
import * as chargeLast from "./chargeLast";
import * as client from "./client";
import * as doctor from "./doctor";
import * as doctorAsk from "./doctorAsk";
import * as hospital from "./hospital";
import * as loading from "./loading";
import * as login from "./login";
import * as main from "./main";
import * as pay from "./pay";
import * as profile from "./profile";
import * as authPhone from "./modal/authPhone";
import * as timer from "./common/timer";
import * as ask from "./ask";
import * as message_list from "./modal/message_list";
import * as event from "./event";
import * as category from "./category";
import * as pushPopup from "./pushPopup";
import * as prepayment from "./prepayment";
import * as prepaymentPayment from "./prepaymentPayment";
import * as prepaymentSettlement from "./prepaymentSettlement";
import * as prepaymentCpm from "./prepaymentCpm";
import * as prepaymentQna from "./prepaymentQna";
import * as hospitalAsk from "./hospitalAsk";
import * as talkQna from "./talkQnaModule";

const rootReducer = combineReducers({
  [modal.name]: modal.reducer,
  [alert.name]: alert.reducer,
  [sidebar.name]: sidebar.reducer,
  [account.name]: account.reducer,
  [chargeLast.name]: chargeLast.reducer,
  [client.name]: client.reducer,
  [doctor.name]: doctor.reducer,
  [doctorAsk.name]: doctorAsk.reducer,
  [hospital.name]: hospital.reducer,
  [loading.name]: loading.reducer,
  [login.name]: login.reducer,
  [main.name]: main.reducer,
  [pay.name]: pay.reducer,
  [profile.name]: profile.reducer,
  [authPhone.name]: authPhone.reducer,
  [timer.name]: timer.reducer,
  [ask.name]: ask.reducer,
  [message_list.name]: message_list.reducer,
  [event.name]: event.reducer,
  [category.name]: category.reducer,
  [pushPopup.name]: pushPopup.reducer,
  [prepayment.name]: prepayment.reducer,
  [prepaymentPayment.name]: prepaymentPayment.reducer,
  [prepaymentSettlement.name]: prepaymentSettlement.reducer,
  [prepaymentCpm.name]: prepaymentCpm.reducer,
  [prepaymentQna.name]: prepaymentQna.reducer,
  [hospitalAsk.name]: hospitalAsk.reducer,
  [talkQna.name]: talkQna.reducer,
});

export default rootReducer;
