import { put, call, takeLatest } from "redux-saga/effects";
import { action as clientAction } from "../modules/client";
import { action as profileAction } from "../modules/profile";

import { apiRequest } from "../server_url";

function* getClientInfo() {
  try {
    const response = yield call(apiRequest, {
      url: "/pay/profile/",
      method: "GET",
    });
    yield put(clientAction.setClientInfo(response.data));
  } catch (err) {}
}

function* postClientInfo(action) {
  try {
    yield call(apiRequest, {
      url: "/pay/profile/",
      method: "POST",
      data: action.payload,
    });
    yield put(profileAction.getProfile());
    yield put(clientAction.getClientInfo());
  } catch (err) {}
}

function* getPrepaymentAgree() {
  try {
    const response = yield call(apiRequest, {
      url: "/client/prepayment/agree/",
      method: "GET",
    });
    yield put(clientAction.setPrepaymentAgree(response.data.is_prepayment_agree));
  } catch (err) {}
}

export function* clientAsync() {
  yield takeLatest(clientAction.getClientInfo, getClientInfo);
  yield takeLatest(clientAction.postClientInfo, postClientInfo);
  yield takeLatest(clientAction.getPrepaymentAgree, getPrepaymentAgree);
}
