const fontFamily = `"Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto"`;

const typography = {
  allVariants: {
    fontFamily,
    fontVariantNumeric: "lining-nums tabular-nums",
    fontFeatureSettings: `"salt" on`,
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  fontFamily,
  fontVariantNumeric: "lining-nums tabular-nums",
  fontFeatureSettings: `"salt" on`,
  fontSize: 13,
  lineHeight: "13px",
  h1: {
    fontSize: 24,
    lineHeight: "24px",
    fontWeight: 600,
    letterSpacing: 0,
  },
  h2: {
    fontSize: 20,
    lineHeight: "20px",
    fontWeight: 600,
    letterSpacing: 0,
  },
  h3: {
    fontSize: 16,
    lineHeight: "16px",
    fontWeight: 600,
    letterSpacing: 0,
  },
  h4: {
    fontSize: 13,
    lineHeight: "13px",
    fontWeight: 600,
    letterSpacing: 0,
  },
  subtitle1: {
    fontSize: 16,
    lineHeight: "16px",
    fontWeight: 500,
    letterSpacing: 0,
  },
  subtitle2: {
    fontSize: 13,
    lineHeight: "13px",
    fontWeight: 500,
    letterSpacing: 0,
  },
  body1: {
    fontSize: 13,
    lineHeight: "19.5px",
    fontWeight: 400,
    letterSpacing: 0,
  },
  body2: {
    fontSize: 11,
    lineHeight: "16.5px",
    fontWeight: 400,
    letterSpacing: 0,
  },
};

export default typography;
