import { put, call, takeLatest, all } from "redux-saga/effects";
import { apiRequest } from "server_url";
import { action as prepaymentAction } from "modules/prepaymentCpm";
import { action as mainAction } from "modules/main";
import { action as profileAction } from "modules/profile";
import { saveExcel } from "helpers/saveExcel";

function* getMoney() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/money/",
      method: "GET",
    });
    yield put(prepaymentAction.setMoney(response.data.prepayment_money));
  } catch (error) {}
}

function* chargeMoney({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/charge/money/",
      method: "POST",
      data: { prepayment_money: payload },
    });
    yield put(prepaymentAction.setMoney(response.data.prepayment_money));
    yield put(profileAction.getProfile());
  } catch (error) {}
}

function* getChargeList({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/charge/spend/list/excel/",
      method: "GET",
      params: {
        page: payload?.page,
        start_date: payload?.startDate,
        end_date: payload?.endDate,
        transaction: payload?.transaction !== "null" ? payload?.transaction : null,
      },
    });
    yield put(prepaymentAction.setChargeList(response.data));
  } catch (error) {}
}

function* exportChargeList({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/charge/spend/list/excel/export/",
      method: "GET",
      responseType: "blob",
      params: {
        start_date: payload?.startDate,
        end_date: payload?.endDate,
        transaction: payload?.transaction !== "null" ? payload?.transaction : null,
      },
    });

    saveExcel(response.data, `미리결제_충전금_내역 [${payload?.startDate} - ${payload?.endDate}]`);
  } catch (error) {}
}

function* getBudget() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/daily/money/",
      method: "GET",
    });
    yield put(prepaymentAction.setBudget(response.data.prepayment_daily_money));
  } catch (error) {}
}

function* putBudget({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/daily/money/",
      method: "PUT",
      data: { prepayment_daily_money: payload },
    });
    yield put(prepaymentAction.setBudget(response.data.prepayment_daily_money));
  } catch (error) {}
}

function* getExpenses() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/spent/money/",
      method: "GET",
    });
    yield put(prepaymentAction.setExpenses(response.data.spent_money));
  } catch (error) {}
}

function* getCpmSummary() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/event/cpm/list/",
      method: "GET",
    });
    yield put(prepaymentAction.setCpmSummary(response.data));
  } catch (error) {}
}

function* getLastMonthSummary() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/last/month/summary/",
      method: "GET",
    });
    yield put(prepaymentAction.setLastMonthSummary(response.data));
  } catch (error) {}
}

function* getOverview() {
  yield put(mainAction.getOverviewPrepayment());
}

function* getCpmData() {
  yield all([getOverview(), getExpenses(), getBudget()]);
}

function* getChargeData({ payload }) {
  yield all([getMoney(), getBudget(), getChargeList({ payload })]);
}

export function* async() {
  yield takeLatest(prepaymentAction.getMoney, getMoney);
  yield takeLatest(prepaymentAction.chargeMoney, chargeMoney);
  yield takeLatest(prepaymentAction.getChargeList, getChargeList);
  yield takeLatest(prepaymentAction.exportChargeList, exportChargeList);

  yield takeLatest(prepaymentAction.getBudget, getBudget);
  yield takeLatest(prepaymentAction.putBudget, putBudget);

  yield takeLatest(prepaymentAction.getExpenses, getExpenses);

  yield takeLatest(prepaymentAction.getCpmSummary, getCpmSummary);
  yield takeLatest(prepaymentAction.getLastMonthSummary, getLastMonthSummary);
  yield takeLatest(prepaymentAction.getCpmData, getCpmData);
  yield takeLatest(prepaymentAction.getChargeData, getChargeData);
}
