import { createSlice } from "@reduxjs/toolkit";
import { getItem } from "components/pages/event/modal/EventCategoriesModal";

const initialDetailState = {
  event: {},
  banner: {},
  sub_categories: [],
  original_banner_text5: "",
};

const initialState = {
  events: [],
  eventLiveCount: null,
  pagination: {
    total_page: 1,
    page: 1,
  },
  allEvents: [],
  pageLimit: localStorage.getItem("event_pageLimit") || 10,

  detail: initialDetailState,
  detail_origin: initialDetailState,

  surgeryCategories: [],
  treatmentsCategories: [],
  surgeryCategoriesInit: [],
  treatmentsCategoriesInit: [],
  allCategories: [],
  mainAllCategories: [],
};

export const name = "event";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getEvents: () => {},
    setEvents: (state, { payload }) => {
      state.eventLiveCount = payload?.filter(item => item.status === 0)?.length;

      localStorage.setItem("event_pageLimit", state.pageLimit);

      state.events = payload?.slice(0, state.pageLimit);
      state.pagination = {
        total_page: Math.ceil(payload?.length / state.pageLimit),
        page_limit: state.pageLimit,
        page: 1,
      };
      state.allEvents = payload;
    },
    setPage: (state, { payload }) => {
      state.events = state.allEvents?.slice(
        (payload - 1) * state.pageLimit,
        payload * state.pageLimit
      );
      state.pagination.page = payload;
    },
    setPageLimit: (state, { payload }) => {
      state.pageLimit = payload;
    },

    getEvent: () => {},
    getEventNCategories: () => {},
    setEvent: (state, { payload }) => {
      const setInitialData = {
        key: new Date().getTime(),
        ...payload,
        banner: {
          ...payload.banner,
          categories: payload.categories?.length ? payload.categories : payload.banner.categories,
        },
        event: {
          ...payload.event,
          category_type:
            state.allCategories?.find(item => item.id === payload.sub_categories[0])?.type === 305
              ? "surgery"
              : state.allCategories?.find(item => item.id === payload.sub_categories[0])?.type ===
                  306 && "treatments",
          subCategories: state.allCategories
            ?.filter(item => payload.sub_categories?.includes(item.id))
            ?.sort(a => (a.id === payload.sub_categories[0] ? -1 : 0)),
        },
      };

      state.detail = { ...setInitialData };
      if (!payload.original_banner_text5) {
        state.detail.original_banner_text5 = payload.banner.text5;
      }

      if (!payload.isLoadSaveData) {
        state.detail_origin = { ...state.detail };
      }
    },
    setInitialDetail: state => {
      state.detail = initialDetailState;
      state.detail_origin = initialDetailState;
    },
    stopEvent: () => {},
    stopEventSuccess: (state, { payload }) => {
      const event = state.events.find(event => event.bid === payload.bid);
      if (event) {
        event.status = 1;
        event.etc = payload.csmemo;
      }
    },
    postEvent: () => {},
    deleteEvent: () => {},
    deleteEventSuccess: (state, { payload }) => {
      state.events = state.events.filter(event => event.bid !== payload);
    },
    inspectDeleteEvent: () => {},
    inspectDeleteEventSuccess: (state, { payload }) => {
      const event = state.events.find(event => event.bid === payload.banner_id);
      if (event) {
        event.inspect_temp_id = null;
        event.inspect_rejected_at = null;
        event.inspect_reject_comment = null;
      }
    },

    toggleAdScopeType: () => {},
    toggleAdScopeTypeSuccess: (state, { payload }) => {
      const { data } = payload;
      const event = state.events.find(event => event.eid === parseInt(data.eid));
      if (event) {
        event.eid__ad_scope_type = data.ad_scope_type;
        if (data.ad_scope_type !== 3) event.eid__gooddoc_external_ad = false;
      }
    },

    toggleExternalAd: () => {},
    toggleExternalAdSuccess: (state, { payload }) => {
      const { data } = payload;
      const event = state.events.find(event => event.eid === parseInt(data.eid));
      if (event) {
        event.eid__external_ad = data.is_external_ad_agreement;
      }
    },

    toggleGooddocExternalAd: () => {},
    toggleGooddocExternalAdSuccess: (state, { payload }) => {
      const { data } = payload;
      const event = state.events.find(event => event.eid === parseInt(data.eid));
      if (event) {
        event.eid__gooddoc_external_ad = data.gooddoc_external_ad;
      }
    },

    setState: (state, { payload }) => {
      if (payload.name === "subCategories") {
        state.detail.sub_categories = payload.value.map(item => item.id);
        state.detail.banner.main_category_id = payload.value[0]?.category1?.id;
      }
      state.detail[payload.type][payload.name] = payload.value;
    },
    setDatesOrigin: (state, { payload }) => {
      state.detail_origin.event.start_date = payload.startDate;
      state.detail_origin.event.end_date = payload.endDate;
    },

    getSurgeryCategories: () => {},
    getTreatmentsCategories: () => {},
    getAllCategories: () => {},
    setCategories: (state, { payload }) => {
      const { type, data } = payload;

      let categories = [];
      data.map(item =>
        item.subcategories?.map(subItem =>
          subItem?.subcategories?.length
            ? subItem?.subcategories.map(lastItem =>
                lastItem?.subcategories?.length
                  ? lastItem.subcategories.map(tag =>
                      categories.push(getItem(tag, [item, subItem, lastItem, tag]))
                    )
                  : categories.push(getItem(lastItem, [item, subItem, lastItem, lastItem]))
              )
            : categories.push(getItem(subItem, [item, subItem, subItem, subItem]))
        )
      );

      const list = data?.map(item => getItem(item, [item]));
      state[type] = list;
      state[`${type}Init`] = categories;

      // 중복으로 여러번 concat되는 케이스 발생
      state.allCategories = state.allCategories.concat(categories)?.reduce((acc, cur) => {
        if (!acc.find(item => item.id === cur.id)) {
          acc.push(cur);
        }
        return acc;
      }, []);
      state.mainAllCategories = state.mainAllCategories.concat(list);
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
