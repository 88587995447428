import { put, call, takeLatest } from "redux-saga/effects";
import { action as hospitalAskAction } from "modules/hospitalAsk";
import { apiRequest } from "server_url";

function* getHospitalAsk() {
  try {
    const response = yield call(apiRequest, {
      url: "/hospital/event/",
      method: "GET",
    });
    yield put(hospitalAskAction.setHospitalAsk(response.data.is_on));
  } catch (err) {}
}

function* toggleHospitalAsk({ payload }) {
  const is_on = payload;
  try {
    yield call(apiRequest, {
      url: "/hospital/event/",
      method: !is_on ? "POST" : "DELETE",
    });
    yield put(hospitalAskAction.setHospitalAsk(!is_on));

    if (!is_on) alert("병원 상담 신청이 활성화 되었습니다.");
    else alert("병원 상담 신청이 비활성화 되었습니다.");
  } catch (err) {}
}

export function* async() {
  yield takeLatest(hospitalAskAction.getHospitalAsk, getHospitalAsk);
  yield takeLatest(hospitalAskAction.toggleHospitalAsk, toggleHospitalAsk);
}
