import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

const Wrapper = styled.div`
  .__react_component_tooltip {
    padding: 0px;
    border-radius: 8px;
    max-width: 500px;
  }
  .__react_component_tooltip.show {
    opacity: 1;
  }
`;

const Content = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
  padding: 10px;
  text-align: left;
`;

const BaseTooltip = ({ id, color, onClick, ...props }) => {
  const colorCode = {
    tint: "#FF508C",
    "rt-tint": "#FF508C",
    primary: "#484760",
    "rt-primary": "#484760",
    babi: "#7264FF",
    "rt-babi": "#7264FF",
  };

  return (
    <Wrapper>
      <ReactTooltip
        id={id}
        className="tooltip"
        scrollHide={false}
        backgroundColor={colorCode[color || id] || colorCode.primary}
        getContent={content => {
          return <Content onClick={onClick} dangerouslySetInnerHTML={{ __html: content }} />;
        }}
        {...props}
      />
    </Wrapper>
  );
};

export default BaseTooltip;
