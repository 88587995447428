import palette from "./palette";
import typography from "./typography";

const helperText = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: palette.grey600.main,
        alignItems: "flex-start",
        ...typography.body1,
      },
    },
  },
};

export default helperText;
