import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  is_on: false,
};

export const name = "hospitalAsk";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getHospitalAsk: () => {},
    toggleHospitalAsk: () => {},
    setHospitalAsk: (state, { payload }) => {
      state.is_on = payload;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
