import Base from "@mui/material/Switch";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { palette, typography } from "../../theme";

export const colorProps = ["primary"];
export const sizeProps = ["small", "large"];

const sizes = {
  small: {
    width: 32,
    height: 20,
    "& .MuiSwitch-switchBase ": {
      width: 20,
      height: 20,
      padding: 3,
      "&.Mui-checked": {
        transform: "translateX(12px)",
      },
    },
    "& .MuiSwitch-thumb": {
      width: 14,
      height: 14,
      boxShadow: "none",
    },
  },
  large: {
    width: 52,
    height: 32,
    "& .MuiSwitch-switchBase ": {
      width: 32,
      height: 32,
      padding: 4,
      "&.Mui-checked": {
        transform: "translateX(20px)",
      },
    },
    "& .MuiSwitch-thumb": {
      width: 24,
      height: 24,
    },
  },
};

const StyledComponent = styled(Base)(({ disabled, _size }) => ({
  padding: 0,
  display: "flex",
  borderRadius: "62.5px",
  "&:active": {
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      // 버튼안에 스위치 넣을 경우 color가 button color로 설정되어 !important 추가함
      color: "#fff !important",
      "& + .MuiSwitch-track": {
        opacity: disabled ? 0.3 : 1,
      },
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#DADADF",
    boxSizing: "border-box",
  },
  ...sizes[_size],
}));

const CustomThemeProvider = ({ theme, children, ...props }) => (
  <ThemeProvider theme={theme}>
    <StyledComponent {...props} color="primary">
      {children}
    </StyledComponent>
  </ThemeProvider>
);

const Switch = ({ color, children, size, ...props }) => (
  <CustomThemeProvider
    theme={createTheme({
      palette: {
        primary: {
          ...palette[color || "primary"],
        },
      },
      typography,
    })}
    _size={size}
    {...props}
  >
    {children}
  </CustomThemeProvider>
);

Switch.propTypes = {
  color: PropTypes.oneOf(colorProps),
  size: PropTypes.oneOf(sizeProps),
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Switch.defaultProps = {
  size: "small",
  disabled: false,
  onClick: undefined,
};

export default Switch;
