import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialDetailState = {
  id: 0,
  status: -1,
  date: "",
  category_id: -1,
  price: 0,
  event_id: 0,
  title: "",
  text: "",
  image_ios: "",
  image_aos: "",
  ename: "",
  event_list: [],
};

export const name = "pushPopup";
const slice = createSlice({
  name,
  initialState: {
    calendar: [],
    list: [],
    paginate: {},
    categories: [],
    detail: initialDetailState,
    services: [],
  },
  reducers: {
    getCalendar: () => {},
    setCalendar: (state, action) => {
      state.calendar = action.payload;
    },
    resetCalendar: state => {
      state.calendar = [];
    },
    purchase: () => {},
    updateCalendar: (state, action) => {
      const { purchase_date, category_id, owned } = action.payload;

      const find = state.calendar?.find(
        event => event.date === purchase_date && event.category_id === category_id
      );

      if (find) {
        find.type = "SOLDOUT";
        find.owned = owned;
      }
    },
    // 추후 쿠폰 여러개 설정 가능할 경우 list를 불러오도록 수정 필요
    removeCouponData: state => {
      state.calendar = state.calendar?.map(event => ({
        ...event,
        coupon_box_id: null,
        discount_price: null,
        discount_rate: null,
        expired_at: null,
      }));
    },

    getList: () => {},
    setList: (state, action) => {
      state.list = action.payload.list;
      state.paginate = action.payload.paginate;
    },
    resetList: state => {
      state.list = [];
      state.paginate = {};
    },
    getRejectMessage: () => {},
    postRefund: () => {},
    refundCompleted: (state, action) => {
      const item = state.list.find(item => (item?.id || item?.ad_id) === action.payload);
      item.refund_at = moment().toISOString();
      item.status = 3;
    },

    getDetail: () => {},
    setDetail: (state, action) => {
      state.detail = action.payload;
    },
    postDetail: () => {},
    initialDetail: state => {
      state.detail = initialDetailState;
    },

    getCategories: () => {},
    setCategories: (state, action) => {
      state.categories = action.payload?.map(item => ({ ...item, value: item.id }));
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
