import MuiRadio from "@mui/material/Radio";
import PropTypes from "prop-types";
import FormControlLabel from "../FormControlLabel";

// 하기 처럼 사용 필요
// import RadioGroup from "@mui/material/RadioGroup";
/* 
<RadioGroup
  name="test"
  defaultValue="2"
  onChange={event => {
    console.log(event.target.value);
  }}
  sx={{ gap: "8px" }}
></RadioGroup>;
*/
const RadioButton = ({ value, label, defaultChecked, disabled, sx, ...props }) => {
  return (
    <FormControlLabel
      control={
        <MuiRadio
          defaultChecked={defaultChecked}
          sx={{
            display: "none",
          }}
          {...{ value, disabled, ...props }}
        />
      }
      sx={{
        margin: 0,
        "& .MuiTypography-root": {
          display: "block",
          width: "150px",
          height: "36px",
          lineHeight: "36px",
          textAlign: "center",
          color: "grey600.main",
          fontWeight: 400,

          borderRadius: "6px",
          border: "solid 1px",
          borderColor: "grey300.main",
          ...sx,
        },
        "& .Mui-disabled": {
          backgroundColor: "grey150.main",
        },
        "& .Mui-checked + .MuiTypography-root": {
          fontWeight: 600,
          color: "primary800.main",
          borderColor: "primary800.main",
        },
      }}
      {...{ label }}
    />
  );
};

RadioButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.any,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  defaultChecked: false,
  disabled: false,
};

export default RadioButton;
