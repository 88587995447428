import React from "react";
import PropTypes from "prop-types";
import "material-symbols/rounded.css";
import palette, { colorNames } from "theme/palette";

export const variantProps = ["outlined", "contained"];
const fontSizes = { xsmall: 16, small: 20, medium: 24, large: 36 };
export const fontSizeProps = Object.keys(fontSizes);
export const colorProps = colorNames;

export const Symbol = ({ name, variant, fontSize, color, style, ...props }) => {
  return (
    <span
      className="material-symbols-rounded"
      style={{
        fontSize: fontSizes[fontSize],
        color: color ? palette[color].main : "inherit",
        fontVariationSettings: `'FILL' ${
          variant === "contained" ? 1 : 0
        }, 'wght' 400, 'GRAD' 0, 'opsz' 24`,
        ...style,
      }}
      {...props}
    >
      {name}
    </span>
  );
};

Symbol.propTypes = {
  name: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(variantProps),
  fontSize: PropTypes.oneOf(fontSizeProps),
  color: PropTypes.string,
};

Symbol.defaultProps = {
  name: "help",
  variant: "outlined",
  fontSize: "medium",
};
