import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Label from "../Label";
import * as Styles from "./styles";

const FormGroup = ({ label, isRequired, children, description }) => {
  return (
    <Styles.Wrapper>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <Styles.InputWrapper>
        {children}
        {description && (
          <Typography variant="body1" color="grey600.main">
            {description}
          </Typography>
        )}
      </Styles.InputWrapper>
    </Styles.Wrapper>
  );
};

FormGroup.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
};

FormGroup.defaultProps = {
  isRequired: false,
};

export default FormGroup;
