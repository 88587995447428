import { createSlice } from "@reduxjs/toolkit";
import { listInitialState } from "./common/initialState";

const initialState = {
  ...listInitialState,
  categories: ["시술 관련", "가격 관련", "시설 관련", "기타 문의"],
  blindReasons: [
    { type: 1, reason: "상업성/광고성 게시물 작성" },
    { type: 2, reason: "중요부위 모자이크 미처리" },
    { type: 3, reason: "개인정보 노출 (연락처, SNS, 카톡 id 등)" },
    { type: 4, reason: "음란성 게시물 작성" },
    { type: 5, reason: "욕설/비방/도배글 작성" },
    { type: 6, reason: "견적 관련 질문 (의료법 저촉)" },
  ],
  newQuestionCount: 0,
};

export const name = "prepayment_qna";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getList: () => {},
    setList: (state, { payload }) => {
      state.list = payload.qna_list;
      state.paginate = payload.paginate;
    },

    putAnswer: () => {},
    deleteAnswer: () => {},
    setAnswer: (state, { payload }) => {
      const { qna_id, answer, status } = payload;

      const find = state.list?.find(item => item.id === qna_id);
      if (find) {
        find.answer = answer;
        find.status = status;
      }
    },

    getNewQuestionCount: () => {},
    setNewQuestionCount: (stage, { payload }) => {
      stage.newQuestionCount = payload;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
