import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import MuiMenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

const StyledMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    minWidth: 100,
    minHeight: 34,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "9px 12px",
    ...theme.typography.subtitle2,
    fontWeight: 400,
    div: {
      display: "flex",
      alignItems: "center",
      gap: 6,
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: theme.palette.grey500.main,
      },
    },
    "&:hover": {
      background: theme.palette.primary100.main,
      fontWeight: 600,
    },
    "&:focus": {
      background: theme.palette.primary100.main,
      fontWeight: 600,
    },
    "&.Mui-disabled": {
      color: theme.palette.grey500.main,
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: theme.palette.grey300.main,
      },
    },
  },
}));

const MenuItem = ({ selected, children, ...props }) => (
  <StyledMenuItem key={`${props.value || props.id}_${selected}`} {...props}>
    <div>{children}</div>
    {selected && <CheckRoundedIcon color="primary700" fontSize="xsmall" />}
  </StyledMenuItem>
);

MenuItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default React.memo(MenuItem);
