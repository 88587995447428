import { all } from "redux-saga/effects";
import { async as sidebarAsync } from "./common/sidebar";
import { accountAsync } from "./account";
import { chargeListAsync } from "./chargeLast";
import { clientAsync } from "./client";
import { doctorAsync } from "./doctor";
import { doctorAskAsync } from "./doctorAsk";
import { hospitalAsync } from "./hospital";
import { loginAsync } from "./login";
import { mainAsync } from "./main";
import { payAsync } from "./pay";
import { profileAsync } from "./profile";
import { telephoneAsync } from "./telephone";
import { timerAsync } from "./common/timer";
import { askAsync } from "./ask";
import { async as eventAsync } from "./event";
import { async as categoryAsync } from "./category";
import { async as pushPopupAsync } from "./pushPopup";
import { async as prepaymentAsync } from "./prepayment";
import { async as prepaymentPaymentAsync } from "./prepaymentPayment";
import { async as prepaymentSettlementAsync } from "./prepaymentSettlement";
import { async as prepaymentCpmAsync } from "./prepaymentCpm";
import { async as prepaymentQna } from "./prepaymentQna";
import { async as hospitalAsk } from "./hospitalAsk";
import { async as talkQna } from "./talkQnaSaga";

export function* rootSaga() {
  // prettier-ignore
  yield all([
    sidebarAsync(),
    accountAsync(),
    chargeListAsync(),
    clientAsync(),
    doctorAsync(),
    doctorAskAsync(),
    hospitalAsync(),
    loginAsync(),
    mainAsync(),
    payAsync(),
    profileAsync(),
    telephoneAsync(),
    timerAsync(),
    askAsync(),
    eventAsync(),
    categoryAsync(),
    pushPopupAsync(),
    prepaymentAsync(),
    prepaymentPaymentAsync(),
    prepaymentSettlementAsync(),
    prepaymentCpmAsync(),
    prepaymentQna(),
    hospitalAsk(),
    talkQna(),
  ]);
}
