import { put, call, takeLatest } from "redux-saga/effects";
import { apiRequest } from "server_url";
import { action as prepaymentSettlementAction } from "modules/prepaymentSettlement";
import { saveExcel } from "helpers/saveExcel";

function* getList({ payload }) {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/payment/statistics/list/",
      method: "GET",
      params: {
        search_word: payload?.keyword,
        start_date: payload?.startDate,
        end_date: payload?.endDate,
        page: payload?.page,
        page_offset: 10,
        sort_key: payload?.sort_key,
        sort_type: payload?.sort_type,
      },
    });

    yield put(prepaymentSettlementAction.setList(response.data));
  } catch (error) {}
}

function* getMonthly() {
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/payment/statistics/monthly/",
      method: "GET",
    });

    yield put(prepaymentSettlementAction.setMonthly(response.data));
  } catch (error) {}
}

function* exportExcel({ payload }) {
  const { startDate, endDate } = payload;
  try {
    const response = yield call(apiRequest, {
      url: "/prepayment_event/payment/statistics/list/excel/",
      method: "GET",
      responseType: "blob",
      params: {
        search_word: payload?.keyword,
        start_date: startDate,
        end_date: endDate,
      },
    });

    saveExcel(
      response.data,
      `미리결제 정산 내역 [${startDate} - ${endDate}]${
        payload?.keyword ? `_${payload?.keyword}` : ""
      }`
    );
  } catch (error) {}
}

export function* async() {
  yield takeLatest(prepaymentSettlementAction.getList, getList);
  yield takeLatest(prepaymentSettlementAction.getMonthly, getMonthly);
  yield takeLatest(prepaymentSettlementAction.exportExcel, exportExcel);
}
