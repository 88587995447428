import { Button } from "@mui/material";
import { FlexEnd } from "components/common/Flex";
import Modal, { Title } from "components/modals/base/Modal";
import React from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  padding: 32px 32px 36px;
`;

const Body = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #7e7e8f;
  text-align: center;
  margin-top: 12px;
`;

const AlertModal = ({ options, close }) => {
  const { title, body, text, button } = options;

  return (
    <Modal
      width={options?.width || 420}
      minWidth={options?.minWidth}
      close={close}
      isBlockBackdropClick
    >
      <ModalContent>
        {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
        {body && <Body dangerouslySetInnerHTML={{ __html: body }} />}
        {text && <Body dangerouslySetInnerHTML={{ __html: text }} />}
        <FlexEnd style={{ marginTop: 24 }}>
          <Button
            size="large"
            variant={button?.variant ? button?.variant : "contained"}
            color={button?.color ? button?.color : "primary"}
            onClick={() => {
              button?.onClick && button?.onClick();
              close("confirm");
            }}
            sx={{ width: "100%" }}
          >
            {button?.text ? button.text : "확인"}
          </Button>
        </FlexEnd>
      </ModalContent>
    </Modal>
  );
};

export default AlertModal;
