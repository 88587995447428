import { useEffect } from "react";
import FontFaceObserver from "fontfaceobserver";
import { createGlobalStyle } from "styled-components";

const useGlobalStyle = createGlobalStyle`
  .font-failed .material-symbols-rounded,
  .font-loading .material-symbols-rounded {
    visibility: hidden;
  }
`;

const useFontLoader = () => {
  useEffect(() => {
    const font = new FontFaceObserver("Material Symbols Rounded");
    document.body.classList.add("font-loading");
    font
      .load()
      .then(() => {
        // 로드 성공
        document.body.classList.remove("font-loading");
      })
      .catch(() => {
        // 로드 실패
        document.body.classList.replace("font-loading", "font-failed");
      });
  }, []);

  return useGlobalStyle;
};

export default useFontLoader;
