export const saveExcel = (data, title = "babialk_export_excel") => {
  let blob = new Blob([data], { type: "application/ms-excel" });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", `${title}.xls`);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(link);
  link.remove();
};
