import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Symbol } from "stories/v2/Symbol";
import { FlexRow, flexCenter, flexColumn } from "components/common/Flex";
import { backgroundIcon } from "components/common/css";
import CustomModal from "components/modals/CustomModal";
import Select from "components/common/select";
import { Button } from "@mui/material";
import SearchIcon from "assets/images/eventCategory/Search.svg";
import DoneIcon from "assets/images/eventCategory/Done.svg";
import CategoriesBox from "./CategoriesBox";
import SelectedCategoriesBox from "./SelectedCategoriesBox";
import ChallengeSectionBanner from "components/pages/main/challengeSection/Banner";
import useModal from "hooks/common/useModal";

export const ModalContent = styled.div`
  ${flexColumn}
  gap: 24px;
  align-items: stretch;
  padding-top: 8px;

  text-align: left;

  .test123 {
    color: red;
  }
`;

export const Group = styled.div`
  ${flexColumn}
  gap: 16px;
`;

export const Label = styled.div`
  ${flexCenter}
  gap: 4px;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #484760;

  &:before {
    content: "";
    display: inline-block;
    ${({ iconType }) => iconType && backgroundIcon(iconType === "search" ? SearchIcon : DoneIcon)}
  }
`;

// 카테고리 선택
export const CategoryWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

// 선택한 카테고리, 대표카테고리
export const GrayBox = styled(Group)`
  padding: 16px;
  background: #f7f7f9;
  border: 1px solid #dadadf;
  border-radius: 4px;
`;

export const GrayBoxTitle = styled.div`
  ${flexCenter}
  gap: 8px;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #604aff;
`;

export const SelectedCategoryWrap = styled(GrayBox)`
  ${GrayBoxTitle} span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #7e7e8f;
  }
`;

export const MainCategoryWrap = styled(GrayBox)`
  ${GrayBoxTitle} span {
    ${flexCenter}
    gap: 4px;

    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #484760;
  }
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ff5630;
  margin-bottom: -8px;
`;

const List = styled.li`
  list-style-position: inside;
  text-indent: -20px;
  margin-left: 20px;
`;

export const getItem = (lastItem, items) => {
  const getName = names => [...new Set(names?.filter(item => item))].join(" > ");

  let cates = {};
  items.map((item, index) => (cates = { ...cates, [`category${index + 1}`]: item }));

  return {
    ...lastItem,
    id: lastItem.id,
    value: lastItem.id,
    name: getName(items.map(item => item?.name)),
    type: lastItem.type,
    typeCode: lastItem.type === 305 ? "surgery" : "treatments",
    typeName: lastItem.type === 305 ? "성형" : "쁘띠/피부",
    ...cates,
  };
};

const EventCategoriesModal = ({ options, close }) => {
  const { type, selected } = options;
  const isSurgery = type === "surgery";

  const surgeryCategories = useSelector(state => state.event.surgeryCategories);
  const treatmentsCategories = useSelector(state => state.event.treatmentsCategories);
  const initSurgeryCategories = useSelector(state => state.event.surgeryCategoriesInit);
  const initTreatmentsCategories = useSelector(state => state.event.treatmentsCategoriesInit);

  const eventCategories = isSurgery ? surgeryCategories : treatmentsCategories;
  const initEventCategories = isSurgery ? initSurgeryCategories : initTreatmentsCategories;

  const modal = useModal();

  // 검색으로 찾기
  const [searchCategories, setSearchCategories] = useState([]);
  // 선택한 카테고리
  const [selectedCategories, _setSelectedCategories] = useState([]);
  // 대표 카테고리
  const [mainCategory, setMainCategory] = useState();

  // 대, 중, 소 카테고리 & 카테고리 태그 리스트
  const [categories1, setCategories1] = useState([]);
  const [categories2, setCategories2] = useState([]);
  const [categories3, setCategories3] = useState([]);
  const [categories4, setCategories4] = useState([]);

  // 대, 중, 소 카테고리 선택값
  const [selectCategory1, setSelectCategory1] = useState();
  const [selectCategory2, setSelectCategory2] = useState();
  const [selectCategory3, setSelectCategory3] = useState();

  const setSelectedCategories = useCallback(
    selectedCategories => {
      _setSelectedCategories(selectedCategories);

      if (!selectedCategories.find(item => item.id === mainCategory)) setMainCategory();
      if (!selectedCategories.length) {
        setSearchCategories(initEventCategories);
      } else {
        const category1 = selectedCategories[0].category1.id;
        setSearchCategories(searchCategories =>
          searchCategories
            ?.filter(item => item.category1.id === category1)
            ?.map(item =>
              selectedCategories?.find(selected => selected.id === item.id)
                ? { ...item, isDisabled: true }
                : { ...item, isDisabled: false }
            )
        );
      }
    },
    [eventCategories, initEventCategories]
  );

  useEffect(() => {
    setCategories1(eventCategories);
    setCategories2([]);
    setCategories3([]);
    setCategories4([]);

    setSearchCategories(initEventCategories);

    if (selected?.length) {
      setSelectedCategories(selected);

      const category1 = selected[0]?.category1;
      const category2 = selected[0]?.category2;
      setMainCategory(selected[0]?.id);
      setSelectCategory1(category1);
      setCategories2(category1?.subcategories);
      setSelectCategory2(category2);
      setCategories3(category2?.subcategories);
    }
  }, [eventCategories]);

  const handleChangeCategory = (selected, setSelectedCategory, setSubCategories) => {
    setSelectedCategory(selected);
    setSubCategories(selected?.subcategories);
  };

  const handleChangeCategory1 = selected => {
    handleChangeCategory(selected, setSelectCategory1, setCategories2);
    setCategories3([]);
    setCategories4([]);
  };

  const handleChangeCategory2 = selected => {
    handleChangeCategory(selected, setSelectCategory2, setCategories3);
    setCategories4([]);
    if (!selected?.subcategories?.length) handleChangeCategory3(selected, selected);
  };

  const handleChangeCategory3 = (selected, category2) => {
    handleChangeCategory(selected, setSelectCategory3, setCategories4);
    if (!selected?.subcategories?.length) handleChangeCategory4(selected, category2, selected);
  };

  const handleChangeCategory4 = (selected, category2, category3, category1) => {
    if (selectedCategories.find(item => item.id === selected.id)) {
      modal.alert("이미 선택한 카테고리 입니다.");
      return;
    }
    const maxCount = 3;
    if (selectedCategories.length >= maxCount) {
      modal.alert(`카테고리는 최대 ${maxCount}개까지 지정할 수 있습니다.`);
      return;
    }

    setSelectedCategories([
      ...selectedCategories,
      getItem(selected, [
        category1?.id ? category1 : selectCategory1,
        category2?.id ? category2 : selectCategory2,
        category3?.id ? category3 : selectCategory3,
        selected,
      ]),
    ]);
  };

  // 검색어 입력
  const handleChangeSearchCategory = selected => {
    const { category1, category2, category3, category4 } = selected;

    handleChangeCategory(category1, setSelectCategory1, setCategories2);
    handleChangeCategory(category2, setSelectCategory2, setCategories3);
    handleChangeCategory(category3, setSelectCategory3, setCategories4);

    handleChangeCategory4(category4, category2, category3, category1);
  };

  const handleClickSelectedCategory = clicked => {
    setSelectedCategories(selectedCategories.filter(item => item.id !== clicked.id));
  };

  return (
    <CustomModal width={isSurgery ? 728 : 800} close={close} title="이벤트 카테고리 선택하기">
      <ModalContent>
        <ChallengeSectionBanner hideButton />
        <Group>
          <Label iconType="search">검색으로 찾기</Label>
          <FlexRow>
            <Select
              searchInput
              value={""}
              options={searchCategories}
              onChange={handleChangeSearchCategory}
              placeholder={`카테고리명을 입력해주세요 (ex. ${
                isSurgery ? "쌍꺼풀, 뒤트임" : "인모드, 리쥬란힐러"
              })`}
              noOptionsMessage={
                <div className="text-left">
                  <List>
                    선택 가능한 카테고리가 없습니다.
                    <br />
                    아래 직접 선택하기로 정확한 카테고리를 찾아보세요.
                  </List>
                </div>
              }
              textAlign="left"
              isArrayValue
            />
          </FlexRow>
        </Group>

        <Group>
          <Label iconType="done">직접 선택하기</Label>

          <CategoryWrap>
            <CategoriesBox
              title="대카테고리"
              list={categories1}
              value={selectCategory1}
              onChange={handleChangeCategory1}
              disabled={Boolean(selectedCategories?.length)}
            />
            <CategoriesBox
              title="중카테고리"
              list={categories2}
              value={selectCategory2}
              onChange={handleChangeCategory2}
              placeholder={
                categories1?.length
                  ? categories1?.find(item => item.subcategories?.length)
                    ? "대카테고리를 선택해 주세요"
                    : ""
                  : " "
              }
            />
            <CategoriesBox
              title="소카테고리"
              list={categories3}
              value={selectCategory3}
              onChange={handleChangeCategory3}
              placeholder={
                categories2?.length
                  ? categories2?.find(item => item.subcategories?.length)
                    ? "중카테고리를 선택해 주세요"
                    : ""
                  : " "
              }
            />
            {!isSurgery && (
              <CategoriesBox
                title="카테고리태그"
                list={categories4}
                value={""}
                onChange={handleChangeCategory4}
                placeholder={
                  categories3?.length ||
                  (categories2?.length &&
                    !categories2?.find(item => item.subcategories?.length)?.name)
                    ? !categories3?.find(item => item.subcategories?.length)
                      ? ""
                      : "소카테고리를 선택해 주세요"
                    : " "
                }
              />
            )}
          </CategoryWrap>
        </Group>

        {selectedCategories?.length > 0 && (
          <>
            <SelectedCategoryWrap>
              <GrayBoxTitle>
                선택한 카테고리
                <span>(최대 3개)</span>
              </GrayBoxTitle>
              <SelectedCategoriesBox
                categories={selectedCategories}
                onClick={handleClickSelectedCategory}
              />
            </SelectedCategoryWrap>

            <MainCategoryWrap>
              <GrayBoxTitle>
                대표 카테고리
                <span>
                  <Symbol name="info" fontSize="xsmall" variant="contained" />
                  대표카테고리로 이벤트를 추천합니다.
                </span>
              </GrayBoxTitle>
              <FlexRow>
                <Select
                  value={mainCategory}
                  options={selectedCategories}
                  onChange={setMainCategory}
                  optionValue="id"
                  placeholder="대표카테고리를 선택해주세요."
                  textAlign="left"
                />
              </FlexRow>
            </MainCategoryWrap>
          </>
        )}

        <Description>
          *이벤트와 맞지 않는 카테고리를 등록할 경우 검수 단계에서 운영자에 의해 변경될 수 있습니다.
        </Description>

        <Button
          onClick={() => {
            if (selectedCategories?.length > 0 && mainCategory) {
              close(
                selectedCategories
                  .filter(item => item.id === mainCategory)
                  .concat(selectedCategories.filter(item => item.id !== mainCategory))
              );
            }
          }}
          variant={selectedCategories?.length > 0 && mainCategory ? "contained" : "outlined"}
          color={selectedCategories?.length > 0 && mainCategory ? "primary" : "secondary"}
          size="large"
          sx={{ padding: "12px" }}
          disabled={!(selectedCategories?.length > 0 && mainCategory > 0)}
        >
          선택완료
        </Button>
      </ModalContent>
    </CustomModal>
  );
};

export default EventCategoriesModal;
