import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("TOKEN"),
};

export const name = "login";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    login: () => {},
    loginWithSMS: () => {},
    loginSuccess: (state, action) => {
      localStorage.setItem("TOKEN", action.payload);
      state.token = action.payload;
    },
    logout: () => {
      localStorage.removeItem("TOKEN");
      return initialState;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
