import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // hospital
  locations: [],
};

export const name = "category";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getLocations: () => {},
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
