import dayjs from "dayjs";

export const today = dayjs().startOf("day");
export const tomorrow = dayjs().add(1, "d").startOf("day");
export const yesterday = dayjs().subtract(1, "d").startOf("day");

export const dateToString = (date = dayjs(), format = "YYYY-MM-DD") => {
  return dayjs(date).format(format);
};

export const datetimeToString = (date = dayjs(), format = "YYYY-MM-DD HH:mm:ss") => {
  return dateToString(date, format);
};

export const firstDayString = (date = dayjs()) => {
  return dateToString(dayjs(date).startOf("month"), "YYYY-MM") + "-01";
};

export const lastDayString = (date = dayjs()) => {
  return dateToString(dayjs(firstDayString(date)).add(1, "month").subtract(1, "day"));
};

export const addYear = (date = dayjs(), year = 1) => {
  return dateToString(dayjs(date).add(year, "year"));
};

export const subYear = (date = dayjs(), year = 1) => {
  return dateToString(dayjs(date).subtract(year, "year"));
};

export const addMonth = (date = dayjs(), month = 1) => {
  return dateToString(dayjs(date).add(month, "month"));
};

export const subMonth = (date = dayjs(), month = 1) => {
  return dateToString(dayjs(date).subtract(month, "month"));
};

export const addDay = (date = dayjs(), day = 6) => {
  return dateToString(dayjs(date).add(day, "day"));
};

export const subDay = (date = dayjs(), day = 6) => {
  return dateToString(dayjs(date).subtract(day, "day"));
};
