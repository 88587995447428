import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  login_phone: "",
  id: "",
  password: "",
  authNumber: "",
};

export const name = "authPhone";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    open: (state, action) => {
      const { id, password, login_phone } = action.payload;
      state.show = true;
      state.id = id;
      state.password = password;
      state.login_phone = login_phone;
      state.authNumber = "";
    },
    close: () => {
      return initialState;
    },
    postAuthNumber: () => {},
    setAuthNumber: (state, action) => {
      state.authNumber = action.payload;
    },
    setInitial: () => {
      return initialState;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
