import React from "react";
import Modal from "./Modal";
import { Symbol } from "stories/v2/Symbol";
import { Wrapper, Buttons, Content, Title } from "./styled";
import { Button, Typography } from "@mui/material";

const ConfirmModal = ({ options, close }) => {
  const { title, text, icon, button, width } = options;

  return (
    <Modal width={width ?? 400} hideCloseButton>
      <Wrapper>
        <Content>
          {icon && (
            <Symbol
              name={icon?.name || icon || "warning"}
              variant="contained"
              color={icon?.color || "grey500"}
              fontSize="large"
            />
          )}
          <Title>{title}</Title>
          <Typography variant="body1" color="text.grey700">
            {text}
          </Typography>
        </Content>
        <Buttons direction={button?.direction || "horizontal"}>
          <Button variant="outlined" color="secondary" size="large" onClick={close}>
            {button?.cancel?.text || button?.cancel || "취소"}
          </Button>
          <Button
            color={button?.color || button?.confirm?.color || "danger"}
            onClick={() => close("confirm")}
            variant="contained"
            size="large"
            style={button?.style || button?.confirm?.style}
          >
            {button?.text || button?.confirm?.text || "확인했어요"}
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

export default ConfirmModal;
